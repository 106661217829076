import {
	QuestionTypes_instruction,
	QuestionnaireSpecWithScoringResult,
} from '@lh/qnr-svc-rest-client';

import { theme } from 'components/providers/StyleProvider/theme';
import i18n from 'i18n';
import {
	getQuestionnaireScore,
	getQuestionnaireTranslations,
} from '../CustomQuestionnaire.utils';

import { Phq8ResultData } from './Layout/IndividualAnswers/Table';

export type Phq8Type =
	| 'gad-minimal-anxiety-category'
	| 'gad-mild-anxiety-category'
	| 'gad-moderate-anxiety-category'
	| 'gad-severe-anxiety-category';

/**
 * @param {string} type - The type of the score. If given undefined, it will return an empty string with
 * a gray background. Can be used for the loading state.
 */
export function getInfoFromType(type?: Phq8Type) {
	switch (type) {
		default:
			return {
				color: theme.colors.gray_70,
				text: i18n.t('web.report.uniqueResponses.total'),
			};
	}
}

export function parseQuestions(
	qnrData: QuestionnaireSpecWithScoringResult
): Phq8ResultData[] {
	const parsedAnswers: Phq8ResultData[] = [];

	const qnrTranslations = getQuestionnaireTranslations(qnrData);

	if (qnrData) {
		qnrData.components.forEach((component, componentIndex) => {
			if (component.type !== QuestionTypes_instruction.INSTRUCTION) {
				const translationKey = component.promptI18nKey;
				if (translationKey) {
					const shouldHaveIndex =
						componentIndex !==
						// We already check if qnrData.result is defined on line 62
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						qnrData!.components?.length - 1;
					parsedAnswers.push({
						question: qnrTranslations[translationKey],
						answers: {
							choices: component.choices.map(
								(choice, choiceIndex) => ({
									...choice,
									text: shouldHaveIndex
										? `${choiceIndex} - ${
												qnrTranslations[
													choice.choiceI18nKey
												]
										  }`
										: qnrTranslations[choice.choiceI18nKey],
								})
							),
						},
					});
				}
			}
		});
	}

	// add the last row of the table as the scorer text
	parsedAnswers.push({
		question: i18n.t('web.report.uniqueResponses.total').toUpperCase(),
		answers: {
			totalScore: getQuestionnaireScore(qnrData),
		},
	});

	return parsedAnswers;
}
