import { H4 } from 'components/shared/designSystem';

import { PhqScoreContainer, H2Med, ScoreContainer } from './PhqScore.style';

export const PHQ_8_MAX_TOTAL_SCORE = '24';

type PhqScoreProps = {
	score: string;
	color: string;
	text: string;
};

export function PhqScore({ score, color, text }: Readonly<PhqScoreProps>) {
	return (
		<PhqScoreContainer $backgroundColor={color}>
			<Score score={score} color={color} />
			<H4>{text}</H4>
		</PhqScoreContainer>
	);
}

type ScoreProps = {
	score: string;
	color: string;
};

function Score({ score, color }: Readonly<ScoreProps>) {
	return (
		<ScoreContainer $backgroundColor={color}>
			<H2Med>{`${score}/${PHQ_8_MAX_TOTAL_SCORE}`}</H2Med>
		</ScoreContainer>
	);
}
