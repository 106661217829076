import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MiniModalReport } from '../AssessmentComponents/MiniModal/MiniModalReport';
import { CDSOverview } from '../CDS/CDSOverview';
import { Report } from '../CognitiveEvaluation.types';
import { cognitiveEvaluationAccessibleTabs } from './cognitiveEvaluationAccessibleTabs';
import { BatteryHeaderFragment } from '../../../generated/graphql';
import { TabbedLayout } from '../../layout/TabbedLayout';
import { TabItem } from '../../layout/TabbedLayout/TabbedLayout';
import { LifeAndHealthReport } from '../SegmentComponents/LifeAndHealth/LifeAndHealthReport';
import { CDSVersions, useCDS } from '../../shared/hooks/useCDS';

type CognitiveEvaluationTabbedLayoutProps = {
	batteryResult: Report;
	batteryParticipant?: BatteryHeaderFragment;
	previousComponentRef?: RefObject<HTMLDivElement>;
	patientReportHandler?: () => void;
	isContentLoading?: boolean;
	setIsContentLoading?: (isLoading: boolean) => void;
};
export const CognitiveEvaluationTabbedLayout = ({
	batteryResult,
	batteryParticipant,
	patientReportHandler,
	previousComponentRef,
	isContentLoading,
	setIsContentLoading,
}: CognitiveEvaluationTabbedLayoutProps): JSX.Element => {
	const { t } = useTranslation();
	const cds = useCDS();

	const batteryType = batteryResult?.batteryResultById?.battery
		?.batteryType as string;

	const cdsRecommendations =
		batteryParticipant?.batteryResultById?.cdsRecommendations ?? [];

	const batteryTabs: TabItem[] = [
		{
			name: t`cds.fullArticle.recommendationsLabel`,
			element: (
				<CDSOverview
					batteryParticipant={batteryParticipant}
					batteryResult={batteryResult}
					// FIXME When graphql is gone
					// @ts-expect-error
					batteryType={batteryType}
					patientReportHandler={patientReportHandler}
					reportLanguage={batteryParticipant?.language}
					setIsLoading={(isLoading: boolean) =>
						onSetIsContentLoading('Recommendations', isLoading)
					}
				/>
			),
			isInitLoading: false,
		},
		{
			name: t`web.report.cognitiveEvaluation.batteryTabs.dcr`,
			element: <MiniModalReport batteryResult={batteryResult} />,
			isInitLoading: false,
		},
		{
			name: t`web.report.cognitiveEvaluation.batteryTabs.lifeHealth`,
			element: (
				<LifeAndHealthReport
					batteryResult={batteryResult}
					isLoading={isContentLoading}
					offsetComponentRef={previousComponentRef}
					setIsLoading={(isLoading: boolean) =>
						onSetIsContentLoading(
							t`web.report.cognitiveEvaluation.batteryTabs.lifeHealth`,
							isLoading
						)
					}
				/>
			),
		},
	];

	const showCDSTab =
		cds !== CDSVersions.V2 &&
		!!(cds === CDSVersions.V1 && cdsRecommendations?.length);

	const accessibleTabs = cognitiveEvaluationAccessibleTabs(
		batteryTabs,
		batteryType,
		showCDSTab
	);

	const [activeTabName, setActiveTabName] = useState(accessibleTabs[0].name);

	const getActiveTab = (name: string) =>
		batteryTabs.find((tab) => tab.name === name) as TabItem;

	const handleTabChange = (name: string) => {
		if (!setIsContentLoading) return;
		const activeTab = getActiveTab(name);
		setActiveTabName(name);
		setIsContentLoading(!!activeTab.isInitLoading);
	};

	const onSetIsContentLoading = (tabName: string, isLoading: boolean) => {
		if (!setIsContentLoading || tabName !== activeTabName) return;
		setIsContentLoading(isLoading);
	};

	return (
		<TabbedLayout
			offsetContainerRef={previousComponentRef}
			tabs={accessibleTabs}
			onTabChange={handleTabChange}
		/>
	);
};
