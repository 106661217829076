import { theme, Typography } from '@lh/eng-web-mosaic-common';
import { Divider, Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

import {
	BatterySummaryAssessmentResult,
	BatterySummaryDomainResult,
} from './BatterySummary.types';
import { CardContainer } from './ResultsByDomainCard.style';
import { ResultsByDomainTable } from './ResultsByDomainTable';

type ResultsByDomainCardProps = {
	assessmentResults: BatterySummaryAssessmentResult[];
	domainResults: BatterySummaryDomainResult[];
};

export function ResultsByDomainCard({
	assessmentResults,
	domainResults,
}: Readonly<ResultsByDomainCardProps>) {
	const { t } = useTranslation();

	return (
		<CardContainer>
			<Stack gap={16}>
				<Stack gap={2}>
					<Typography.H4>
						{t('web.report.summary.table.title')}
					</Typography.H4>
					<Typography.P2 color={theme.color.bodyTextSecondary}>
						{t('web.report.summary.table.description')}
					</Typography.P2>
				</Stack>
				<Divider />
				<ResultsByDomainTable
					assessmentResults={assessmentResults}
					domainResults={domainResults}
					floatingLabel={t('web.report.summary.table.domains')}
					scoreColumnName={t('web.report.summary.table.keyResult')}
				/>
			</Stack>
		</CardContainer>
	);
}
