import { Stack } from '@mantine/core';

import { BatterySummaryData } from './BatterySummary.types';
import { ImpressionCard } from './ImpressionCard';
import { ResultsByDomainCard } from './ResultsByDomainCard';
import { SummaryDisclaimer } from './SummaryDisclaimer';

type BatterySummaryProps = BatterySummaryData;

export function BatterySummary({
	assessmentResults,
	domainResults,
	impression,
}: Readonly<BatterySummaryProps>) {
	return (
		<Stack gap={24} mb={8}>
			<ImpressionCard {...impression} />
			<ResultsByDomainCard
				assessmentResults={assessmentResults}
				domainResults={domainResults}
			/>
			<SummaryDisclaimer />
		</Stack>
	);
}
