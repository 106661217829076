import { theme, Typography } from '@lh/eng-web-mosaic-common';
import { Stack } from '@mantine/core';

import { Trans, useTranslation } from 'react-i18next';

import { DomainScore } from '../../mosaic';

import {
	CognitiveImpression,
	CognitiveImpressionDomains,
} from './BatterySummary.types';
import {
	CardContainer,
	ImpressionLabel,
	ScoresContainer,
} from './ImpressionCard.style';

const TITLE_BY_IMPRESSION: Record<CognitiveImpression, string> = {
	IMPAIRED: 'web.report.summary.impression.title.impaired',
	MIXED: 'web.report.summary.impression.title.mixed',
	UNIMPAIRED: 'web.report.summary.impression.title.unimpaired',
};
const SUBTITLE_BY_IMPRESSION: Record<CognitiveImpression, string> = {
	IMPAIRED: 'web.report.summary.impression.subtitle.impaired',
	MIXED: 'web.report.summary.impression.subtitle.mixed',
	UNIMPAIRED: 'web.report.summary.impression.subtitle.unimpaired',
};

type ImpressionCardProps = {
	domains: CognitiveImpressionDomains;
	value: CognitiveImpression;
};

export function ImpressionCard({
	domains,
	value,
}: Readonly<ImpressionCardProps>) {
	const { t } = useTranslation();

	return (
		<CardContainer>
			<Stack gap={4}>
				<Typography.H4>
					<ImpressionLabel>
						{t('web.report.summary.impression.label')}
					</ImpressionLabel>
					{t(TITLE_BY_IMPRESSION[value])}
				</Typography.H4>
				<Typography.H4
					color={theme.color.bodyTextSecondary}
					weight='500'
				>
					{t(SUBTITLE_BY_IMPRESSION[value])}
				</Typography.H4>
			</Stack>
			<ScoresContainer>
				<DomainScore
					description={domains.impaired.join(', ')}
					status='RED'
					title={t('web.report.summary.impression.domains.red', {
						count: domains.impaired.length,
					})}
					value={domains.impaired.length}
				/>
				<DomainScore
					description={domains.mixed.join(', ')}
					status='YELLOW'
					title={t('web.report.summary.impression.domains.yellow', {
						count: domains.mixed.length,
					})}
					value={domains.mixed.length}
				/>
				<DomainScore
					description={domains.unimpaired.join(', ')}
					status='GREEN'
					title={t('web.report.summary.impression.domains.green', {
						count: domains.unimpaired.length,
					})}
					value={domains.unimpaired.length}
				/>
			</ScoresContainer>
			<Typography.P1 color={theme.color.bodyTextSecondary}>
				<Trans
					components={[<sup />]}
					i18nKey='web.report.summary.impression.disclaimer'
				/>
			</Typography.P1>
		</CardContainer>
	);
}
