import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuestionnaireQuery } from 'api/questionnaire';
import { Questionnaire } from 'enums/questionnaire';
import { LoadingLayout, RCRLayout } from '../../shared/ReportLoader';
import { Report } from '../CognitiveEvaluation.types';
import {
	QnrRowData,
	getQuestionnaireScore,
	parseQuestionnaireData,
} from '../CustomQuestionnaire.utils';

import { parseQuestions, removeDoubleQuotes } from './NeurogenLayout.utils';
import {
	Divider,
	Header,
	NeurogenLayoutWrapper,
	Score,
	ScoreContainer,
	ScoreDescription,
	SectionCard,
	TotalScoreContainer,
} from './NeurogenLayout.style';
import { Table } from './Table';

export const NEUROGEN_MAX_SCORE = 2;

interface NeurogenLayoutProps {
	neurogenQuestionnaireData?: Report;
	segmentResult?: DeepSegmentResult;
	mockedReportData?: NeurogenParsedData;
}

type NeurogenParsedData = {
	questions: QnrRowData[];
	score: number | string;
};

export function NeurogenLayout({
	neurogenQuestionnaireData,
	segmentResult,
	mockedReportData,
}: Readonly<NeurogenLayoutProps>) {
	const { segmentResultId } = useMemo(() => {
		const { segmentResultId } = parseQuestionnaireData(
			Questionnaire.NEUROGEN,
			neurogenQuestionnaireData,
			segmentResult
		);

		return { segmentResultId };
	}, [neurogenQuestionnaireData]);
	const { data: qnrData, isLoading: qnrLoading } = useQuestionnaireQuery(
		neurogenQuestionnaireData?.batteryResultById?.id ?? '',
		segmentResultId ?? ''
	);

	const [reportData, setReportData] = useState<NeurogenParsedData | null>(
		mockedReportData ?? null
	);

	useEffect(() => {
		if (qnrData) {
			const score = getQuestionnaireScore(qnrData);
			const qnrRowData = parseQuestions(qnrData);
			// the questions are stored with double qoutes,
			// we can't remove them from the service since it's used
			// on the assessment side, so we remove them here
			const questionsWithoutQuotes = removeDoubleQuotes(qnrRowData);

			setReportData({
				questions: questionsWithoutQuotes,
				score,
			});
		}
	}, [qnrData]);

	if (!mockedReportData && qnrLoading) {
		return <LoadingLayout title='web.report.neurogen.titleReport' />;
	}

	if (!reportData) {
		return (
			<RCRLayout
				description='web.report.unknown'
				title='web.report.neurogen.titleReport'
			/>
		);
	}

	return (
		<NeurogenLayoutWrapper>
			<ScoreSection score={reportData.score} />
			<Table elements={reportData.questions} />
		</NeurogenLayoutWrapper>
	);
}

type ScoreSectionProps = {
	score: number | string;
};

function ScoreSection({ score }: Readonly<ScoreSectionProps>) {
	const { t } = useTranslation();

	return (
		<SectionCard>
			<Header>{t('web.report.neurogen.titleReport')}</Header>
			<Divider />
			<ScoreContainer>
				<TotalScoreContainer>
					<Score>{`${score}/${NEUROGEN_MAX_SCORE}`}</Score>
				</TotalScoreContainer>
				<ScoreDescription>
					{t('web.report.neurogen.issues')}
				</ScoreDescription>
			</ScoreContainer>
		</SectionCard>
	);
}
