import { theme } from '@lh/eng-web-mosaic-common';

import { styled } from 'styled-components';

import { DomainStatus } from '../../mosaic';

const DOMAIN_STATUS_TO_COLOR: Record<
	DomainStatus,
	{ background: string; border: string }
> = {
	GREEN: {
		background: theme.colors.green_lightest,
		border: theme.colors.green,
	},
	RED: {
		background: theme.colors.orange_lightest,
		border: theme.colors.orange,
	},
	YELLOW: {
		background: theme.colors.yellow_lightest,
		border: theme.colors.yellow,
	},
};

export const Table = styled.table<{ size: 'small' | 'large' }>`
	border-collapse: separate;
	margin-top: 27px;
	position: relative;
	table-layout: fixed;
	width: 100%;

	thead {
		th:nth-child(1),
		th:nth-child(2) {
			width: ${({ size }) => (size === 'large' ? '20%' : '15%')};
		}

		th:nth-child(1) {
			border-right: none;
			border-top-right-radius: 0;
		}

		th:nth-child(2) {
			border-top-left-radius: 0;
			vertical-align: bottom;
		}
	}

	tbody:not(:first-child) {
		tr td:first-child {
			border-right: none;
		}

		tr:not(:last-child) {
			td {
				border-bottom-color: ${({ theme }) => theme.colors.gray_60};
			}
		}

		tr:last-child {
			td:nth-child(1) {
				border-bottom-left-radius: 4px;
			}

			td:nth-child(2) {
				border-bottom-right-radius: 4px;
			}

			td:not(:nth-child(1)):not(:nth-child(2)) {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}
		}
	}
`;

export const THeader = styled.th<{ status?: DomainStatus }>`
	background-color: ${({ status, theme }) =>
		status
			? DOMAIN_STATUS_TO_COLOR[status].background
			: theme.colors.gray_80};
	border: 1px solid
		${({ status, theme }) =>
			status
				? DOMAIN_STATUS_TO_COLOR[status].border
				: theme.colors.gray_60};
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	height: 60px;
	padding: 8px;
	vertical-align: top;
`;

export const TData = styled.td<{ status?: DomainStatus }>`
	border-left: 1px solid
		${({ theme, status }) =>
			status
				? DOMAIN_STATUS_TO_COLOR[status].border
				: theme.colors.gray_60};
	border-right: 1px solid
		${({ theme, status }) =>
			status
				? DOMAIN_STATUS_TO_COLOR[status].border
				: theme.colors.gray_60};
	border-bottom: 1px solid
		${({ theme, status }) =>
			status
				? DOMAIN_STATUS_TO_COLOR[status].border
				: theme.colors.gray_60};
	padding: 12px;
`;

export const EmptyTData = styled.td`
	width: 4px;
`;
export const EmptyTHeader = styled.td`
	width: 4px;
`;

export const FloatingHeader = styled.tbody<{ size: 'small' | 'large' }>`
	background-color: ${({ theme }) => theme.colors.gray_90};
	border: 1px solid ${({ theme }) => theme.colors.gray_60};
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	display: flex;
	align-items: center;
	height: 27px;
	padding: 0 8px;
	position: absolute;
	top: -28px;
	left: ${({ size }) =>
		size === 'large' ? 'calc(40% + 4px)' : 'calc(30% + 4px)'};
	width: ${({ size }) =>
		size === 'large' ? 'calc(60% - 4px)' : 'calc(70% - 4px)'};

	span {
		color: ${({ theme }) => theme.color.bodyTextSecondary};
		text-transform: uppercase;
	}
`;
