import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { useEffect, useMemo, useState } from 'react';

import { useQuestionnaireQuery } from 'api/questionnaire';
import { Questionnaire } from 'enums/questionnaire';
import { LoadingLayout, RCRLayout } from '../../shared/ReportLoader';
import { Report } from '../CognitiveEvaluation.types';
import {
	getQuestionnaireScore,
	parseQuestionnaireData,
} from '../CustomQuestionnaire.utils';

import { Phq8Type, getInfoFromType, parseQuestions } from './Phq8Report.utils';
import { Phq8Layout } from './Layout';
import { Phq8ResultData } from './Layout/IndividualAnswers/Table';

type Phq8ReportData = {
	questions: Phq8ResultData[];
	score: {
		category: Phq8Type;
		total: string;
	};
};

type Props = {
	data?: Report;
	segmentResult?: DeepSegmentResult;
	mockedReportData?: Phq8ReportData;
};

export function Phq8Report({
	data,
	segmentResult,
	mockedReportData,
}: Readonly<Props>) {
	const { segmentResultId } = useMemo(() => {
		const { segmentResultId } = parseQuestionnaireData(
			Questionnaire.PHQ_8,
			data,
			segmentResult
		);

		return { segmentResultId };
	}, [data]);

	const { data: qnrData, isLoading: qnrLoading } = useQuestionnaireQuery(
		data?.batteryResultById?.id ?? '',
		segmentResultId ?? ''
	);

	const [reportData, setReportData] = useState<Phq8ReportData | null>(
		mockedReportData ?? null
	);
	useEffect(() => {
		if (qnrData) {
			const score = getQuestionnaireScore(qnrData).toString();
			const category = (qnrData?.scores?.category?.score.toString() ??
				'') as Phq8Type;
			const questions = parseQuestions(qnrData);

			setReportData({
				questions,
				score: {
					category,
					total: score,
				},
			});
		}
	}, [qnrData]);

	const { color, text } = useMemo(
		() => getInfoFromType(reportData?.score.category),
		[reportData?.score.category]
	);

	if (!mockedReportData && qnrLoading) {
		return <LoadingLayout title='web.report.phq8.titleReport' />;
	}

	if (!reportData) {
		return (
			<RCRLayout
				description='web.report.unknown'
				title='web.report.phq8.titleReport'
			/>
		);
	}

	return (
		<Phq8Layout
			color={color}
			score={reportData.score.total}
			text={text}
			tableData={reportData.questions}
		/>
	);
}
