import { t } from 'i18n';

import { BatteryType } from '../../../generated/graphql';
import { TabItem } from '../../layout/TabbedLayout';

export const cognitiveEvaluationAccessibleTabs = (
	batteryTabs: TabItem[],
	batteryType: string,
	showCDSTab: boolean
): TabItem[] => {
	if (batteryType === BatteryType.Lhq) {
		return batteryTabs?.filter(
			(tab) =>
				tab?.name ===
				t`web.report.cognitiveEvaluation.batteryTabs.lifeHealth`
		);
	}
	if (batteryType === BatteryType.Dcr) {
		return batteryTabs?.filter(
			(tab) =>
				tab?.name === t`web.report.cognitiveEvaluation.batteryTabs.dcr`
		);
	}
	if (!showCDSTab) {
		return batteryTabs?.filter(
			(tab) => tab?.name !== t`cds.fullArticle.recommendationsLabel`
		);
	}
	return batteryTabs;
};
