import { Typography } from '@lh/eng-web-mosaic-common';

import { styled } from 'styled-components';

import { Pill } from './Pill';

export const ScorePill = styled(Pill)`
	border-radius: 4px;
	gap: 2px;
	min-height: 34px;
	padding: 4px;
	text-transform: lowercase;
`;

export const TrialsScorePill = styled(ScorePill)<{ $foreground: string }>`
	border-bottom: 1px solid ${({ $foreground }) => $foreground};
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	min-height: 24px;
`;

export const TrialPill = styled(Typography.C)`
	background-color: ${({ theme }) => theme.colors.gray_70};
	border-radius: 4px;
	padding: 2px;
`;

export const Norm = styled(Typography.P2)`
	text-transform: lowercase;
`;
