import { styled } from 'styled-components';

export const CardContainer = styled.div`
	background-color: ${({ theme }) => theme.color.white};
	border-radius: 20px;
	box-shadow: 0px 2px 10px 0px rgba(23, 24, 32, 0.1);
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 24px;
`;
