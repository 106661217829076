import { GradeMarks } from '@lh/qnr-svc-rest-client';

import { CellContext } from '@tanstack/react-table/';
import { t } from 'i18next';

import { QnrRowData } from 'components/report/CustomQuestionnaire.utils';
import { P1 } from 'components/shared/designSystem';
import { CheckmarkIcon } from 'components/table/icons/Checkmark';
import { CloseIcon } from 'components/table/icons/Close';
import { NEUROGEN_MAX_SCORE } from '../NeurogenLayout';

import {
	FooterResultContainer,
	P1Med,
	P1Semi,
	QuestionTableContainer,
	SpanSemi,
	StyledContentContainer,
	StyledIconContainer,
	TotalLabel,
} from './Table.style';

export const NEUROGEN_TABLE_COLUMNS = [
	{
		accessorKey: 'question',
		header: () => (
			<P1Med>
				{t(
					'web.report.orientationQuestionnaireReport.questionTitle'
				).toUpperCase()}
			</P1Med>
		),
		cell: (props: CellContext<QnrRowData, unknown>) => {
			const value = props.getValue<QnrRowData['question']>();
			const rows = props.table.getRowModel().rows;
			if (props.row.index === rows.length - 1)
				return <TotalLabel>{value}</TotalLabel>;

			return (
				<QuestionTableContainer>
					<SpanSemi>Q{props.row.index + 1}: </SpanSemi>
					{value}
				</QuestionTableContainer>
			);
		},
	},
	{
		accessorKey: 'answers',
		header: () => (
			<P1Med>
				{t(
					'web.report.orientationQuestionnaireReport.selectionTitle'
				).toUpperCase()}
			</P1Med>
		),
		cell: (props: CellContext<QnrRowData, unknown>) => {
			const value = props.getValue<QnrRowData['answers']>();

			if ('totalScore' in value) {
				if (typeof value.totalScore === 'number')
					return (
						<FooterResult
							correct={NEUROGEN_MAX_SCORE - value.totalScore}
							incorrect={value.totalScore}
						/>
					);
				return (
					<FooterResult correct={0} incorrect={NEUROGEN_MAX_SCORE} />
				);
			}
			// Icon Display
			const displayIcon = GRADE_ICONS[value.grade];

			return (
				<StyledContentContainer>
					<StyledIconContainer
						positivelyAnswered={
							value.grade === GradeMarks.GREEN_CHECK
						}
					>
						{displayIcon}
					</StyledIconContainer>
					<P1>{value.answerText}</P1>
				</StyledContentContainer>
			);
		},
	},
];

const GRADE_ICONS = {
	[GradeMarks.RED_X]: <CloseIcon size='24px' />,
	[GradeMarks.GREEN_CHECK]: <CheckmarkIcon size='30px' />,
	[GradeMarks.NOT_GRADED]: null,
};

type FooterResultProps = {
	incorrect: number;
	correct: number;
};

function FooterResult({ correct, incorrect }: Readonly<FooterResultProps>) {
	return (
		<FooterResultContainer>
			<StyledContentContainer>
				<StyledIconContainer positivelyAnswered={false}>
					<CloseIcon size='24px' />
				</StyledIconContainer>
				<P1Semi>{incorrect}</P1Semi>
			</StyledContentContainer>
			<StyledContentContainer>
				<StyledIconContainer positivelyAnswered>
					<CheckmarkIcon size='30px' />
				</StyledIconContainer>
				<P1Semi>{correct}</P1Semi>
			</StyledContentContainer>
		</FooterResultContainer>
	);
}
