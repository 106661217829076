import { createContext, useContext } from 'react';

type ChangeTab = (name: string) => void;
export const TabsContext = createContext<ChangeTab | null>(null);

export function useTabsContext() {
	const context = useContext(TabsContext);

	if (context === null) {
		throw new Error(
			'useTabsContext must be used within <TabsContext.Provider />'
		);
	}

	return context;
}
