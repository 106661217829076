import { MetricItem } from '@lh/eng-platform-battery-service-rest-client';

import {
	BatterySummaryAssessmentResult,
	BatterySummaryData,
} from '../BatterySummary';
import { Report } from '../CognitiveEvaluation.types';

import { DACMetricKey, DACMetrics } from './DACReport.types';

function parseMetrics(metricItems: MetricItem[]) {
	const metrics: DACMetrics = {
		[DACMetricKey.AssessmentSummaries]: [],
		[DACMetricKey.DomainResults]: [],
		[DACMetricKey.SummaryResult]: {
			impaired_domains: [],
			impression: 'UNIMPAIRED',
			mixed_domains: [],
			unimpaired_domains: [],
		},
	};

	for (const item of metricItems) {
		const key = item.key as DACMetricKey;
		if (!metrics[key]) {
			continue;
		}

		metrics[key] = JSON.parse(item.value);
	}

	return metrics;
}

function metricsToData(metrics: DACMetrics): BatterySummaryData {
	const assessmentSummaries = metrics[DACMetricKey.AssessmentSummaries];
	const domainResults = metrics[DACMetricKey.DomainResults];
	const summaryResult = metrics[DACMetricKey.SummaryResult];

	return {
		assessmentResults: assessmentSummaries.map((summary) => {
			let result: BatterySummaryAssessmentResult = {
				domains: summary.relevant_domains,
				maxScore: summary.key_results.max_score,
				name: summary.name,
				norm: summary.key_results.norm,
				normType: summary.key_results.norm_type,
				status: summary.assessment_status,
				resultType: 'DEFAULT',
				score: summary.key_results.score,
				unit: summary.key_results.unit,
			};

			if (summary.key_results.result_type === 'TWO_TRIALS') {
				result = {
					...result,
					resultType: 'TWO_TRIALS',
					trial1MaxScore: summary.key_results.trial1_max_score ?? 0,
					trial1Score: summary.key_results.trial1_score ?? 0,
					trial2MaxScore: summary.key_results.trial2_max_score ?? 0,
					trial2Score: summary.key_results.trial2_score ?? 0,
				};
			}

			return result;
		}),
		domainResults: domainResults.map((result) => ({
			details: result.domain_status_detail,
			name: result.domain_name,
			status: result.domain_status,
		})),
		impression: {
			domains: {
				impaired: summaryResult.impaired_domains,
				mixed: summaryResult.mixed_domains,
				unimpaired: summaryResult.unimpaired_domains,
			},
			value: summaryResult.impression,
		},
	};
}

export function getDACData(report: Report) {
	if (!report.batteryResultById?.metricItems) {
		return null;
	}

	const { metricItems } = report.batteryResultById;
	const metrics = parseMetrics(metricItems);
	const data = metricsToData(metrics);
	return data;
}
