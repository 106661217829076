import {
	AssessmentType,
	DeepAssessmentResult,
	DeepSegmentResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';

import { t } from 'i18n';

import { Questionnaire } from '../../enums/questionnaire';
import { TabItem } from '../layout/TabbedLayout';

import { MiniModalReport } from './AssessmentComponents/MiniModal/MiniModalReport';
import { BdstReport } from './BDST';
import { Report } from './CognitiveEvaluation.types';
import { DCTClockLayout } from './DCTClockLayout';
import { FastReport } from './FAST/FastReport';
import { Gad7Report } from './Gad7';
import { GdsReport } from './GeriatricDepressionScale';
import { HearingScreenerReportLayout } from './HearingScreener/HearingScreenerReport';
import { NeurogenLayout } from './Neurogen';
import { OrientationLayout } from './OrientationQuestionaire';
import { LifeAndHealthReport } from './SegmentComponents/LifeAndHealth/LifeAndHealthReport';
import { RecallRecording } from './SegmentComponents/RecallRecording/RecallRecording';
import { TrailsLayout } from './TrailsLayout';
import { PhonemicLayout } from './custom/Fluency/Phonemic';
import { SemanticLayout } from './custom/Fluency/Semantic';
import { DelayedRecallReport } from './custom/PVLT/DelayedRecall';
import { DelayedRecognitionReport } from './custom/PVLT/DelayedRecognition';
import { ImmediateRecallReport } from './custom/PVLT/ImmediateRecall';
import { IadlLayout } from './custom/iadl/IadlLayout/IadlLayout';
import { Lhq15 } from './LHQ15';
import { Phq8Report } from './Phq8';

export function generateDynamicTabs(batteryResult: Report) {
	const batteryTabs: TabItem[] = [];

	if (batteryResult.batteryResultById) {
		const { assessmentResults } = batteryResult.batteryResultById;

		assessmentResults.forEach((assessmentResult) => {
			const { segmentResults } = assessmentResult;

			const assessmentTab = assessmentTabIdentifier(
				assessmentResult,
				batteryResult
			);

			if (assessmentTab) {
				batteryTabs.push(assessmentTab);
			} else {
				segmentResults.forEach((segmentResult) => {
					const tab = tabIdentifier(segmentResult, batteryResult);
					if (tab) {
						batteryTabs.push(tab);
					}
				});
			}
		});
	}

	return batteryTabs;
}

function assessmentTabIdentifier(
	assessmentResult: DeepAssessmentResult,
	batteryResult: Report
): TabItem | undefined {
	const { assessment } = assessmentResult;

	switch (assessment?.assessmentType) {
		case AssessmentType.Dcr:
			return {
				name: t`web.report.cognitiveEvaluation.batteryTabs.dcr`,
				element: <MiniModalReport batteryResult={batteryResult} />,
			};
		default:
			return;
	}
}

function tabIdentifier(
	segmentResult: DeepSegmentResult,
	batteryResult: Report
): TabItem | undefined {
	const { segment } = segmentResult;

	switch (segment.segmentType) {
		case SegmentType.BackwardsDigitSpan:
			return {
				name: segment.name,
				element: (
					<BdstReport
						batteryResult={batteryResult}
						segmentResult={segmentResult}
					/>
				),
			};

		case SegmentType.RecallImmediate:
			return {
				name: segment.name,
				element: (
					<RecallRecording
						segment={segmentResult}
						subTitle={t`web.dcrReport.recordings.recall.immediate.subtitle`}
						title={t`web.dcrReport.recordings.recall.immediate.title`}
						tooltipText={t`web.dcrReport.recordings.recall.immediate.tooltipText`}
					/>
				),
			};

		case SegmentType.PhonemicFluency:
			return {
				name: segment.name,
				element: <PhonemicLayout segmentResult={segmentResult} />,
			};

		case SegmentType.CustomQuestionnaire: {
			if (segment.metadata?.qnrId) {
				if (segment.metadata.qnrId === Questionnaire.FAST) {
					return {
						name: t`web.report.fastReport.fastEmpty`,
						element: (
							<FastReport
								data={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}

				if (segment.metadata.qnrId === Questionnaire.GAD_7) {
					return {
						name: t`web.report.gad7.gadEmpty`,
						element: (
							<Gad7Report
								data={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}

				if (segment.metadata.qnrId === Questionnaire.GDS) {
					return {
						name: t`web.report.gds.gdsEmpty`,
						element: (
							<GdsReport
								batteryResult={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}

				if (segment.metadata.qnrId === Questionnaire.ADL) {
					return {
						name: t`web.report.iadl.tab`,
						element: (
							<IadlLayout
								type='adl'
								iadlQuestionnaireData={batteryResult}
							/>
						),
					};
				}

				if (segment.metadata.qnrId === Questionnaire.IADL) {
					return {
						name: t`web.report.iadl.tab`,
						element: (
							<IadlLayout iadlQuestionnaireData={batteryResult} />
						),
					};
				}
				if (segment.metadata.qnrId === Questionnaire.LHQ15) {
					return {
						name: t`web.report.patientReport.lhq.title`,
						element: (
							<Lhq15
								batteryResult={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}
				if (segment.metadata.qnrId === Questionnaire.NEUROGEN) {
					return {
						name: t`web.report.neurogen.title`,
						element: (
							<NeurogenLayout
								neurogenQuestionnaireData={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}
				if (segment.metadata.qnrId === Questionnaire.PHQ_8) {
					return {
						name: t`web.report.phq8.title`,
						element: (
							<Phq8Report
								data={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}

				if (segment.metadata.qnrId === Questionnaire.ORIENTATION_6) {
					return {
						name: t`web.report.orientationQuestionnaireReport.orientationEmpty`,
						element: (
							<OrientationLayout
								orientationQuestionnaireData={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}

				if (segment.metadata.qnrId === Questionnaire.NEUROGEN) {
					return {
						name: t`web.report.neurogen.title`,
						element: (
							<NeurogenLayout
								neurogenQuestionnaireData={batteryResult}
								segmentResult={segmentResult}
							/>
						),
					};
				}
			}

			return;
		}

		case SegmentType.RecallDelayed:
			return {
				name: segment.name,
				element: (
					<RecallRecording
						segment={segmentResult}
						subTitle={t`web.dcrReport.recordings.recall.delayed.subtitle`}
						title={t`web.dcrReport.recordings.recall.delayed.title`}
						tooltipText={t`web.dcrReport.recordings.recall.delayed.tooltipText`}
					/>
				),
			};

		case SegmentType.SemanticFluency:
			return {
				name: segment.name,
				element: <SemanticLayout segmentResult={segmentResult} />,
			};

		case SegmentType.Clock:
			return {
				name: segment.name,
				element: <DCTClockLayout segmentResult={segmentResult} />,
			};

		case SegmentType.Lhq15:
		case SegmentType.Lhq32:
			return {
				name: segment.name,
				element: <LifeAndHealthReport segmentResult={segmentResult} />,
			};

		case SegmentType.HearingScreener:
			return {
				name: segment.name,
				element: (
					<HearingScreenerReportLayout
						segmentResult={segmentResult}
					/>
				),
			};

		case SegmentType.TrailsA:
		case SegmentType.TrailsB:
		case SegmentType.TrailsV2A:
		case SegmentType.TrailsV2B:
			return {
				name: segment.name,
				element: <TrailsLayout segmentResult={segmentResult} />,
			};

		case SegmentType.ImmediateRecall6:
			return {
				name: segment.name,
				element: (
					<ImmediateRecallReport segmentResult={segmentResult} />
				),
			};

		case SegmentType.DelayedRecall6:
			return {
				name: segment.name,
				element: <DelayedRecallReport segmentResult={segmentResult} />,
			};

		case SegmentType.DelayedRecognition:
			return {
				name: segment.name,
				element: (
					<DelayedRecognitionReport segmentResult={segmentResult} />
				),
			};

		default:
			return;
	}
}
