import { Report } from '../CognitiveEvaluation.types';
import { DACMetricKey } from './DACReport.types';

export function isDACBattery(report: Report) {
	if (!report.batteryResultById?.metricItems?.length) {
		return false;
	}

	return Boolean(
		report.batteryResultById?.metricItems?.every(
			(d) =>
				d.key === DACMetricKey.AssessmentSummaries ||
				d.key === DACMetricKey.DomainResults ||
				d.key === DACMetricKey.SummaryResult
		)
	);
}
