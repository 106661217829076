import {
	GradeMarks,
	QuestionTypes_instruction,
	QuestionnaireSpecWithScoringResult,
} from '@lh/qnr-svc-rest-client';

import { theme } from 'components/providers/StyleProvider/theme';
import { Icon, P1 } from 'components/shared/designSystem';
import { CheckmarkInternal } from 'components/table/icons/CheckmarkInternal';
import { icons } from 'enums/icons';
import i18n from 'i18n';
import {
	QnrRowData,
	getQuestionnaireScore,
	getQuestionnaireTranslations,
} from '../CustomQuestionnaire.utils';

import { IconContainer, SelectionsContainer } from './NeurogenLayout.style';

export function getSelections(grade: GradeMarks, text: string) {
	if (grade === GradeMarks.GREEN_CHECK)
		return (
			<SelectionsContainer>
				<CheckmarkInternal color={theme.color.iconSuccess} />
				<P1>{text}</P1>
			</SelectionsContainer>
		);

	return (
		<SelectionsContainer>
			<IconContainer size={theme.spacing.lg}>
				<Icon
					tabIndex={-1}
					icon={icons.Close}
					color={theme.color.iconAlert}
					iconHeight={12}
					iconWidth={12}
				/>
			</IconContainer>
			<P1>{text}</P1>
		</SelectionsContainer>
	);
}

export function parseQuestions(
	qnrData: QuestionnaireSpecWithScoringResult
): QnrRowData[] {
	const parsedAnswers: QnrRowData[] = [];

	const qnrTranslations = getQuestionnaireTranslations(qnrData);

	if (qnrData) {
		qnrData.components.forEach((component) => {
			if (component.type !== QuestionTypes_instruction.INSTRUCTION) {
				const translationKey = component.promptI18nKey;
				component.choices.forEach((choice) => {
					if (choice.answer && translationKey) {
						parsedAnswers.push({
							question: qnrTranslations[translationKey],
							answers: {
								grade: choice.answer.grade,
								answerText:
									qnrTranslations[choice.choiceI18nKey],
								value: choice.value,
							},
						});
					}
				});
			}
		});
	}

	// add the last row of the table as the scorer text
	parsedAnswers.push({
		question: i18n.t('web.report.uniqueResponses.total').toUpperCase(),
		answers: {
			totalScore: getQuestionnaireScore(qnrData),
		},
	});

	return parsedAnswers;
}

export function removeDoubleQuotes(questions: QnrRowData[]): QnrRowData[] {
	return questions.map((element) => {
		return {
			...element,
			question: element.question.replaceAll(/“|”/g, ''),
		};
	});
}

export const mockedNeurogenResponse: QnrRowData[] = [
	{
		question: 'What is today’s date?',
		answers: {
			answerText: 'No Attempt',
			grade: GradeMarks.RED_X,
			value: 2,
		},
	},
	{
		question: 'What month is it?',
		answers: {
			answerText: 'Correct',
			grade: GradeMarks.GREEN_CHECK,
			value: 1,
		},
	},
	{
		question: 'What year is it?',
		answers: {
			answerText: 'No Attempt',
			grade: GradeMarks.RED_X,
			value: 2,
		},
	},
	{
		question: 'What day of the week is it?',
		answers: {
			answerText: 'Incorrect',
			grade: GradeMarks.RED_X,
			value: 0,
		},
	},
	{
		question: 'What is the name of this place?',
		answers: {
			answerText: 'No Attempt',
			grade: GradeMarks.RED_X,
			value: 2,
		},
	},
	{
		question: 'What city (or town) are we in?',
		answers: {
			answerText: 'Incorrect',
			grade: GradeMarks.RED_X,
			value: 0,
		},
	},
];
