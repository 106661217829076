import { enumeration, EnumItem } from './enumeration';

export type Icons = {
	ActionPlanPieChart: EnumItem;
	ActionRepeat: EnumItem;
	Add: EnumItem;
	AddOutlined: EnumItem;
	AddSolid: EnumItem;
	AddUser: EnumItem;
	AddUserOutlined: EnumItem;
	AddUserOutlineSparkle: EnumItem;
	AddUserSolid: EnumItem;
	AlertOutline: EnumItem;
	AlertShieldSolid: EnumItem;
	AlertSolid: EnumItem;
	ArrowCircleLeft: EnumItem;
	ArrowCircleRight: EnumItem;
	ArrowDecrease: EnumItem;
	ArrowDown: EnumItem;
	ArrowFlag: EnumItem;
	ArrowIncrease: EnumItem;
	ArrowLeft: EnumItem;
	ArrowRight: EnumItem;
	ArrowUp: EnumItem;
	Badge: EnumItem;
	BeMindfulLHQ: EnumItem;
	BeMindfulTapped: EnumItem;
	BeMindfulUntapped: EnumItem;
	BrainCenterDotOutlined: EnumItem;
	BuildYourVillageLHQ: EnumItem;
	BuildYourVillageTapped: EnumItem;
	BuildYourVillageUntapped: EnumItem;
	CameraPlusOutlined: EnumItem;
	CareGiver: EnumItem;
	CheckboxCheck: EnumItem;
	CheckboxEmpty: EnumItem;
	CheckMark: EnumItem;
	CheckboxSideCheck: EnumItem;
	CheckmarkOutlined: EnumItem;
	CheckmarkSolid: EnumItem;
	Clock: EnumItem;
	Close: EnumItem;
	CloseOutlined: EnumItem;
	CloseSolid: EnumItem;
	Configure: EnumItem;
	NoteClipboard: EnumItem;
	Download: EnumItem;
	EatGreenAndLeanLHQ: EnumItem;
	EatGreenAndLeanTapped: EnumItem;
	EatGreenAndLeanUntapped: EnumItem;
	ClipboardSearch: EnumItem;
	EmptyClipboardSearch: EnumItem;
	EyeClosedOutlined: EnumItem;
	EyeOpenOutlined: EnumItem;
	ExternalLink: EnumItem;
	GetGoodRestLHQ: EnumItem;
	GetGoodRestTapped: EnumItem;
	GetGoodRestUntapped: EnumItem;
	HeartInMindSolid: EnumItem;
	HeartShapedPersonOutlined: EnumItem;
	Home: EnumItem;
	IconRestart: EnumItem;
	Indeterminate: EnumItem;
	Info: EnumItem;
	InfoOutlined: EnumItem;
	InviteOutlined: EnumItem;
	Kebab: EnumItem;
	KeepHealthInCheckLHQ: EnumItem;
	KeepHealthInCheckTapped: EnumItem;
	KeepHealthInCheckUntapped: EnumItem;
	LearnSomethingNewLHQ: EnumItem;
	LearnSomethingNewTapped: EnumItem;
	LearnSomethingNewUntapped: EnumItem;
	LeftNotch: EnumItem;
	LoaderWhite: EnumItem;
	Logo: EnumItem;
	LogoSmall: EnumItem;
	LogoStacked: EnumItem;
	MoveEveryDayLHQ: EnumItem;
	MoveEveryDayTapped: EnumItem;
	MoveEveryDayUntapped: EnumItem;
	NoDataUser: EnumItem;
	NoPathway: EnumItem;
	NotFoundUser: EnumItem;
	NonModifiableRiskFactorLHQ: EnumItem;
	Organization: EnumItem;
	Pathway: EnumItem;
	Pause: EnumItem;
	PencilFilled: EnumItem;
	PencilOutlined: EnumItem;
	Play: EnumItem;
	PrintOutlined: EnumItem;
	RemoveUserSolid: EnumItem;
	Results: EnumItem;
	Skip10Ahead: EnumItem;
	Skip10Back: EnumItem;
	SortArrowDownDisabled: EnumItem;
	SortArrowDownEnabled: EnumItem;
	SortArrowUpDisabled: EnumItem;
	SortArrowUpEnabled: EnumItem;
	StayTrueToPurposeLHQ: EnumItem;
	StayTrueToPurposeTapped: EnumItem;
	StayTrueToPurposeUntapped: EnumItem;
	Star: EnumItem;
	StarOutlined: EnumItem;
	Subtract: EnumItem;
	SubtractSmall: EnumItem;
	Support: EnumItem;
	TestGears: EnumItem;
	Timer: EnumItem;
	TraditionalArrowDown: EnumItem;
	TraditionalArrowLeft: EnumItem;
	TraditionalArrowRight: EnumItem;
	TraditionalArrowUp: EnumItem;
	Users: EnumItem;
	UserSolid: EnumItem;
	UKCA: EnumItem;
	LeftPointerArrow: EnumItem;
	MagnifyingGlass: EnumItem;
	WarningRound: EnumItem;
	WarningOutlined: EnumItem;
	Distractor: EnumItem;
	GreenCheckmark: EnumItem;
	RedClose: EnumItem;
};
const _icons = {
	ActionPlanPieChart: {
		value: 'ActionPlanPieChart',
		width: 277,
		height: 277,
	},
	ActionRepeat: { value: 'ActionRepeat', width: 24, height: 24 },
	Add: { value: 'Add', width: 24, height: 24 },
	AddOutlined: { value: 'AddOutlined', width: 18, height: 18 },
	AddSolid: { value: 'AddSolid', width: 18, height: 18 },
	AddUser: { value: 'AddUser', width: 24, height: 24 },
	AddUserOutlined: { value: 'AddUserOutlined', width: 16, height: 19 },
	AddUserOutlineSparkle: {
		value: 'AddUserOutlineSparkle',
		width: 158,
		height: 100,
	},
	AddUserSolid: { value: 'AddUserSolid', width: 16, height: 19 },
	AlertOutline: { value: 'AlertOutline', width: 16, height: 16 },
	AlertShieldSolid: { value: 'AlertShieldSolid', width: 14, height: 16 },
	AlertSolid: { value: 'AlertSolid', width: 18, height: 18 },
	ArrowCircleLeft: { value: 'ArrowCircleLeft', width: 18, height: 18 },
	ArrowCircleRight: { value: 'ArrowCircleRight', width: 18, height: 18 },
	ArrowDecrease: { value: 'ArrowDecrease', width: 19, height: 12 },
	ArrowDown: { value: 'ArrowDown', width: 14, height: 8 },
	ArrowFlag: { value: 'ArrowFlag', width: 40, height: 40 },
	ArrowIncrease: { value: 'ArrowIncrease', width: 19, height: 12 },
	ArrowLeft: { value: 'ArrowLeft', width: 8, height: 14 },
	ArrowRight: { value: 'ArrowRight', width: 8, height: 14 },
	ArrowUp: { value: 'ArrowUp', width: 14, height: 8 },
	Badge: { value: 'Badge', width: 20, height: 20 },
	BeMindfulLHQ: { value: 'BeMindfulLHQ', width: 32, height: 32 },
	BeMindfulTapped: { value: 'BeMindfulTapped', width: 32, height: 32 },
	BeMindfulUntapped: { value: 'BeMindfulUntapped', width: 36, height: 36 },
	BrainCenterDotOutlined: {
		value: 'BrainCenterDotOutlined',
		width: 34,
		height: 34,
	},
	BuildYourVillageLHQ: {
		value: 'BuildYourVillageLHQ',
		width: 33,
		height: 33,
	},
	BuildYourVillageTapped: {
		value: 'BuildYourVillageTapped',
		width: 33,
		height: 33,
	},
	BuildYourVillageUntapped: {
		value: 'BuildYourVillageUntapped',
		width: 36,
		height: 36,
	},
	CameraPlusOutlined: { value: 'CameraPlusOutlined', width: 19, height: 18 },
	CareGiver: { value: 'CareGiver', width: 27, height: 32 },
	CheckboxCheck: { value: 'CheckboxCheck', width: 24, height: 24 },
	CheckboxEmpty: { value: 'CheckboxEmpty', width: 24, height: 24 },
	CheckMark: { value: 'CheckMark', width: 16, height: 16 },
	CheckboxSideCheck: { value: 'CheckboxSideCheck', width: 16, height: 13 },
	CheckmarkOutlined: { value: 'CheckmarkOutlined', width: 18, height: 18 },
	CheckmarkSolid: { value: 'CheckmarkSolid', width: 18, height: 18 },
	Clock: { value: 'Clock', width: 16, height: 16 },
	Close: { value: 'Close', width: 14, height: 14 },
	CloseOutlined: { value: 'CloseOutlined', width: 18, height: 18 },
	CloseSolid: { value: 'CloseSolid', width: 18, height: 18 },
	Configure: { value: 'Configure', width: 24, height: 24 },
	NoteClipboard: { value: 'NoteClipboard', width: 18, height: 18 },
	Download: { value: 'Download', width: 16, height: 12 },
	EatGreenAndLeanLHQ: {
		value: 'EatGreenAndLeanLHQ',
		width: 32,
		height: 32,
	},
	EatGreenAndLeanTapped: {
		value: 'EatGreenAndLeanTapped',
		width: 32,
		height: 32,
	},
	EatGreenAndLeanUntapped: {
		value: 'EatGreenAndLeanUntapped',
		width: 36,
		height: 36,
	},
	EmptyClipboardSearch: {
		value: 'EmptyClipboardSearch',
		width: 163,
		height: 100,
	},
	ClipboardSearch: {
		value: 'ClipboardSearch',
		width: 60,
		height: 60,
	},
	EyeClosedOutlined: { value: 'EyeClosedOutlined', width: 18, height: 17 },
	EyeOpenOutlined: { value: 'EyeOpenOutlined', width: 18, height: 12 },
	ExternalLink: { value: 'ExternalLink', width: 24, height: 24 },
	Frailty: { value: 'Frailty', width: 24, height: 29 },
	FocusAreaStar: { value: 'FocusAreaStar', width: 8, height: 8 },
	GetGoodRestLHQ: { value: 'GetGoodRestLHQ', width: 32, height: 32 },
	GetGoodRestTapped: { value: 'GetGoodRestTapped', width: 32, height: 32 },
	GetGoodRestUntapped: {
		value: 'GetGoodRestUntapped',
		width: 36,
		height: 36,
	},
	HeartInMindSolid: { value: 'HeartInMindSolid', width: 32, height: 32 },
	HeartShapedPersonOutlined: {
		value: 'HeartShapedPersonOutlined',
		width: 34,
		height: 34,
	},
	Home: { value: 'Home', width: 18, height: 18 },
	IconRestart: { value: 'IconRestart', width: 24, height: 24 },
	Indeterminate: { value: 'Indeterminate', width: 14, height: 4 },
	Info: { value: 'Info', width: 18, height: 18 },
	InfoOutlined: { value: 'InfoOutlined', width: 18, height: 18 },
	InviteOutlined: { value: 'InviteOutlined', width: 18, height: 19 },
	Kebab: { value: 'Kebab', width: 4, height: 16 },
	KeepHealthInCheckLHQ: {
		value: 'KeepHealthInCheckLHQ',
		width: 32,
		height: 32,
	},
	KeepHealthInCheckTapped: {
		value: 'KeepHealthInCheckTapped',
		width: 32,
		height: 32,
	},
	KeepHealthInCheckUntapped: {
		value: 'KeepHealthInCheckUntapped',
		width: 36,
		height: 36,
	},
	LearnSomethingNewLHQ: {
		value: 'LearnSomethingNewLHQ',
		width: 32,
		height: 32,
	},
	LearnSomethingNewTapped: {
		value: 'LearnSomethingNewTapped',
		width: 32,
		height: 32,
	},
	LearnSomethingNewUntapped: {
		value: 'LearnSomethingNewUntapped',
		width: 36,
		height: 36,
	},
	LeftNotch: {
		value: 'LeftNotch',
		width: 9,
		height: 36,
	},
	LoaderWhite: { value: 'LoaderWhite', width: 24, height: 24 },
	Logo: { value: 'Logo', width: 168, height: 50 },
	LogoSmall: { value: 'LogoSmall', width: 60, height: 66 },
	LogoStacked: { value: 'LogoStacked', width: 284, height: 161 },
	MoveEveryDayLHQ: { value: 'MoveEveryDayLHQ', width: 32, height: 32 },
	MoveEveryDayTapped: { value: 'MoveEveryDayTapped', width: 32, height: 32 },
	MoveEveryDayUntapped: {
		value: 'MoveEveryDayUntapped',
		width: 36,
		height: 36,
	},
	NoDataUser: { value: 'NoDataUser', width: 146, height: 100 },
	NotFoundUser: { value: 'NotFoundUser', width: 220, height: 100 },
	NonModifiableRiskFactorLHQ: {
		value: 'NonModifiableRiskFactorLHQ',
		width: 36,
		height: 36,
	},
	NoPathway: { value: 'NoPathway', width: 162, height: 100 },
	Organization: { value: 'Organization', width: 18, height: 18 },
	Pathway: { value: 'Pathway', width: 24, height: 24 },
	Pause: { value: 'Pause', width: 32, height: 32 },
	PencilFilled: { value: 'PencilFilled', width: 24, height: 24 },
	PencilOutlined: { value: 'PencilOutlined', width: 20, height: 20 },
	Play: { value: 'Play', width: 32, height: 32 },
	PrintOutlined: { value: 'PrintOutlined', width: 24, height: 24 },
	RemoveUserSolid: { value: 'RemoveUserSolid', width: 16, height: 19 },
	Results: { value: 'Results', width: 20, height: 20 },
	Skip10Ahead: { value: 'Skip10Ahead', width: 32, height: 32 },
	Skip10Back: { value: 'Skip10Back', width: 32, height: 32 },
	SortArrowDownEnabled: {
		value: 'SortArrowDownEnabled',
		width: 8,
		height: 5,
	},
	SortArrowDownDisabled: {
		value: 'SortArrowDownDisabled',
		width: 8,
		height: 5,
	},
	SortArrowUpEnabled: { value: 'SortArrowUpEnabled', width: 8, height: 5 },
	SortArrowUpDisabled: { value: 'SortArrowUpDisabled', width: 8, height: 5 },
	StayTrueToPurposeLHQ: {
		value: 'StayTrueToPurposeLHQ',
		width: 32,
		height: 32,
	},
	StayTrueToPurposeTapped: {
		value: 'StayTrueToPurposeTapped',
		width: 32,
		height: 32,
	},
	StayTrueToPurposeUntapped: {
		value: 'StayTrueToPurposeUntapped',
		width: 36,
		height: 36,
	},
	Star: { value: 'Star', width: 24, height: 24 },
	StarOutlined: { value: 'StarOutlined', width: 24, height: 24 },
	Subtract: { value: 'Subtract', width: 24, height: 24 },
	SubtractSmall: { value: 'SubtractSmall', width: 16, height: 16 },
	Support: { value: 'Support', width: 18, height: 18 },
	TestGears: {
		value: 'TestGears',
		width: 12,
		height: 12,
	},
	Timer: { value: 'Timer', width: 19, height: 18 },
	TraditionalArrowDown: {
		value: 'TraditionalArrowDown',
		width: 12,
		height: 16,
	},
	TraditionalArrowLeft: {
		value: 'TraditionalArrowLeft',
		width: 12,
		height: 16,
	},
	TraditionalArrowRight: {
		value: 'TraditionalArrowRight',
		width: 12,
		height: 16,
	},
	TraditionalArrowUp: { value: 'TraditionalArrowUp', width: 12, height: 16 },
	Users: { value: 'Users', width: 20, height: 16 },
	UserSolid: { value: 'UserSolid', width: 15, height: 18 },
	UKCA: { value: 'UKCA', width: 40, height: 40 },
	LeftPointerArrow: { value: 'LeftPointerArrow', width: 16, height: 12 },
	WarningSolid: { value: 'WarningSolid', width: 24, height: 24 },
	MagnifyingGlass: { value: 'MagnifyingGlass', width: 14, height: 14 },
	WarningRound: { value: 'WarningRound', width: 75, height: 75 },
	WarningOutlined: { value: 'WarningOutlined', width: 24, height: 24 },
	Distractor: { value: 'Distractor', width: 16, height: 16 },
	GreenCheckmark: { value: 'GreenCheckmark', width: 16, height: 16 },
	RedClose: { value: 'RedClose', width: 16, height: 16 },
};

export const icons = enumeration<typeof _icons>(_icons);
