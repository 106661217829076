import styled, { css } from 'styled-components';

import { MediaQueries } from 'components/providers/StyleProvider/MediaQueries';
import { H2 } from 'components/shared/designSystem';

export const PhqScoreContainer = styled.div<{
	$backgroundColor: string;
}>(
	({ $backgroundColor, theme: { borderRadius, spacing } }) => css`
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: ${spacing.sm};
		background-color: ${$backgroundColor}33;
		width: 380px;
		padding: ${spacing.md};
		border-radius: ${borderRadius.searchBox};

		@media ${MediaQueries.maxWidth.lg} {
			width: 100%;
		}
	`
);

export const ScoreContainer = styled.div<{
	$backgroundColor: string;
}>(
	({ $backgroundColor, theme: { borderRadius, spacing } }) => css`
		background-color: ${$backgroundColor};
		padding: ${spacing.xs};
		min-width: 115px;
		height: 52px;
		border-radius: ${borderRadius.searchBox};
		text-align: center;
	`
);

export const H2Med = styled(H2)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
		line-height: 44px;
	`
);
