import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TabbedLayout } from '../../layout/TabbedLayout';

import { BatterySummary } from '../BatterySummary';
import { Report } from '../CognitiveEvaluation.types';
import { generateDynamicTabs } from '../generateDynamicTabs';

import { getDACData } from './getDACData';
import { isDACBattery } from './isDACBattery';

type DACReportProps = {
	report: Report;
};

export function DACReport({ report }: Readonly<DACReportProps>) {
	const { data, tabs } = useMemo(() => {
		const data = getDACData(report);
		const tabs = generateDynamicTabs(report);

		return {
			data,
			tabs,
		};
	}, [report]);
	const { t } = useTranslation();

	if (!data) {
		return null;
	}

	return (
		<TabbedLayout
			tabs={[
				{
					element: <BatterySummary {...data} />,
					name: t('web.report.summary.label'),
				},
				...tabs,
			]}
		/>
	);
}

DACReport.is = isDACBattery;
