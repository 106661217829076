import { TabbedLayout } from '../layout/TabbedLayout';

import { Report } from './CognitiveEvaluation.types';
import { DACReport } from './DAC';

import { generateDynamicTabs } from './generateDynamicTabs';

type TabbedReportProps = {
	report: Report;
};

export function TabbedReport({ report }: Readonly<TabbedReportProps>) {
	if (DACReport.is(report)) {
		return <DACReport report={report} />;
	}

	const tabs = generateDynamicTabs(report);
	return <TabbedLayout tabs={tabs} />;
}
