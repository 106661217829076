import {
	BatteryResultState,
	BatteryResultStateCategory,
	BatteryResultStatus,
	DeepBatteryResult,
	SegmentMetadataCategory,
} from '@lh/eng-platform-battery-service-rest-client';
import { Report } from '../CognitiveEvaluation.types';

const DAC_BATTERY_RESULT: DeepBatteryResult = {
	id: '8d11cd6b-86e8-4729-84a0-9c21c8628802',
	organizationId: '2101dc0d-3d8e-4387-b814-6f6f64917785',
	participantId: 'bea6b37e-2865-4ed6-b761-3f035d6105ff',
	batteryId: '0664b6b8-b36c-4e3f-913c-3790ddc00966',
	startTime: '2024-12-13T15:55:56.878Z',
	endTime: '2024-12-17T16:22:37.044Z',
	rawDataUrl:
		's3://linus-ingest-platform-dev/2101dc0d-3d8e-4387-b814-6f6f64917785/8d11cd6b-86e8-4729-84a0-9c21c8628802',
	proctorId: '6b5cc7d7-de21-402b-a5aa-e9ec12530937',
	assignmentId: 'b98a0782-9411-4cb7-99b0-2332b9609040',
	isExpectingMetrics: false,
	status: BatteryResultStatus.AnalysisComplete,
	relatedResults: [],
	statusContext: {
		state: BatteryResultState.AnalysisComplete,
		category: BatteryResultStateCategory.Complete,
		display: 'Analysis Complete',
		createdAt: '2024-12-17T16:23:34.703523+00:00',
	},
	metricItems: [
		{
			id: '3ad0fdbc-1e5c-443d-a039-bb021f744d49',
			key: 'rdac_assessment_summaries',
			value: '[{"name":"PVLT-6 Immediate Recall","key_results":{"result_type":"TWO_TRIALS","score":9,"unit":"responses","norm":71,"norm_type":"Percentile","max_score":12,"trial1_score":4,"trial1_max_score":6,"trial2_score":5,"trial2_max_score":6},"relevant_domains":["Executive Function","Memory & Learning","Complex Attention"],"assessment_status":"UNIMPAIRED","requires_clinician_review":false},{"name":"PVLT-6 Delayed Recall","key_results":{"result_type":"DEFAULT","score":5,"unit":"responses","norm":95,"norm_type":"Percentile","max_score":6},"relevant_domains":["Memory & Learning"],"assessment_status":"UNIMPAIRED","requires_clinician_review":false},{"name":"PVLT-6 Delayed Recognition","key_results":{"result_type":"DEFAULT","score":6,"unit":"responses","norm":100,"norm_type":"Percentile","max_score":6},"relevant_domains":["Executive Function","Memory & Learning","Complex Attention"],"assessment_status":"UNIMPAIRED","requires_clinician_review":false},{"name":"Semantic Fluency","key_results":{"result_type":"DEFAULT","score":2,"unit":"examplars","norm":1,"norm_type":"Percentile","max_score":null},"relevant_domains":["Executive Function","Language"],"assessment_status":"IMPAIRED","requires_clinician_review":false},{"name":"BDST: Any Order","key_results":{"result_type":"DEFAULT","score":14,"unit":"responses","norm":3,"norm_type":"Quartile","max_score":15},"relevant_domains":["Complex Attention"],"assessment_status":"UNIMPAIRED","requires_clinician_review":false},{"name":"BDST: Serial Order","key_results":{"result_type":"DEFAULT","score":14,"unit":"responses","norm":4,"norm_type":"Quartile","max_score":15},"relevant_domains":["Executive Function"],"assessment_status":"UNIMPAIRED","requires_clinician_review":false}]',
			algorithmVersion: '3.0.0',
		},
		{
			id: '5699ed61-b5ae-47e8-8143-76d73ac40c4e',
			key: 'rdac_summary_result',
			value: '{"impression":"IMPAIRED","impaired_domains":["Language"],"mixed_domains":["Executive Function"],"unimpaired_domains":["Complex Attention","Memory & Learning"]}',
			algorithmVersion: '3.0.0',
		},
		{
			id: 'f503ca9a-605b-47ab-b1a6-96f1ee55bdc0',
			key: 'rdac_domain_results',
			value: '[{"domain_status":"YELLOW","domain_name":"Executive Function","domain_status_detail":"Mixed results"},{"domain_status":"RED","domain_name":"Language","domain_status_detail":"Sole assessment impaired"},{"domain_status":"GREEN","domain_name":"Memory & Learning","domain_status_detail":"No impairment"},{"domain_status":"GREEN","domain_name":"Complex Attention","domain_status_detail":"No impairment"}]',
			algorithmVersion: '3.0.0',
		},
	],
	assessmentResults: [
		{
			id: '36b85557-9275-4d05-99cf-545c22467e4d',
			assessmentId: '5550e77e-4428-4cce-b590-dc85f61f2312',
			batteryResultId: '8d11cd6b-86e8-4729-84a0-9c21c8628802',
			hasProcessingError: false,
			isComplete: false,
			isExpectingMetrics: false,
			allSegmentResultsAnalysisComplete: false,
			createdAt: '2024-12-17T16:22:37.045Z',
			updatedAt: '2024-12-17T16:22:37.045Z',
			segmentResults: [
				{
					id: 'e59600ef-e83c-4705-9341-4a93dc004854',
					segmentId: '54bc2736-5c3b-11ee-8c99-0242ac120002',
					rawDataUrl:
						's3://linus-ingest-platform-dev/2101dc0d-3d8e-4387-b814-6f6f64917785/8d11cd6b-86e8-4729-84a0-9c21c8628802/segments/e59600ef-e83c-4705-9341-4a93dc004854',
					createdAt: '2024-12-17T16:22:37.045Z',
					hasProcessingError: false,
					isExpectingMetrics: true,
					isExpectingResults: true,
					assessmentResultId: '36b85557-9275-4d05-99cf-545c22467e4d',
					hasReceivedResults: false,
					segment: {
						id: '54bc2736-5c3b-11ee-8c99-0242ac120002',
						name: 'Immediate Recall',
						displayKey: 'Immediate Recall',
						segmentType: 'IMMEDIATE_RECALL_6',
						isExpectingResults: true,
						isExpectingMetrics: true,
						metadata: null,
						audienceType: 'SUBJECT',
					},
					metricItems: [
						{
							id: '1e9dba6d-e408-45d5-b529-d715b0f7c4ba',
							key: 'pvlt6_trial1_percentile',
							value: '80',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '6ba22e3c-0fdb-443b-8c29-be897c7996e8',
							key: 'pvlt6_impairment_threshold_adjusted_by_education',
							value: 'false',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'e1695431-8aab-4f49-a0f9-f606ffce79f6',
							key: 'pvlt6_impairment_classification',
							value: 'UNIMPAIRED',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '94c1ea2c-a088-42bb-9f1d-503e1df35585',
							key: 'pvlt6_trial2_impairment_classification',
							value: 'UNIMPAIRED',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'e71ec304-cab7-494e-9cbc-410661c133e4',
							key: 'pvlt6_trial1_impairment_classification',
							value: 'UNIMPAIRED',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'aa9fe980-850b-41b8-879b-1c08b559598e',
							key: 'pvlt6_trial2_impairment_cutoff',
							value: '3',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'c259e0f5-4f30-4fce-a586-b7a269c85984',
							key: 'pvlt6_trial1_impairment_cutoff',
							value: '2',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '8f054d7b-67e2-44ae-be47-bf363c3af12c',
							key: 'pvlt6_age_range',
							value: '60 - 64',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '4ae3126f-62b6-4278-971b-49aad0c58871',
							key: 'pvlt_recall_score_trial_2',
							value: '5',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'db2728f2-72dd-4834-b803-cea23330a2b5',
							key: 'pvlt_transcription_confidences_trial_2',
							value: '["0.997","0.996","0.997","0.996","0.997"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '0517f4ec-0f92-4006-b235-e838a39eb83e',
							key: 'pvlt_transcribed_words_trial_2',
							value: '["pineapple","drill","stapler","cantaloupe","eraser"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '35b429d4-c0c3-4302-9763-2e1005653e0e',
							key: 'pvlt_speech_active_duration_trial_2',
							value: '5.611000000000001',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '9b4e3d91-ac16-48f0-807d-fb58bcde356d',
							key: 'pvlt_speech_end_time_trial_2',
							value: '5.69',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '52dd910e-67de-445d-8567-0ed7aae4f8e7',
							key: 'pvlt_speech_start_time_trial_2',
							value: '0.079',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '37377d78-fb1b-4d3b-bfe7-0d5845c609fb',
							key: 'pvlt_audio_duration_trial_2',
							value: '9.84',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'f05b2a66-4a89-4c0b-b7b7-2063ef5ee386',
							key: 'pvlt_expected_words_trial_2',
							value: '["pineapple","drill","stapler","cantaloupe","eraser","pliers"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'dc8bbe2b-d511-4304-99d5-7507cc7b1fb4',
							key: 'pvlt_test_type_trial_2',
							value: 'IMMEDIATE_RECALL_6',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '1505c000-de9a-40ed-8dc3-ed09c75b8f0d',
							key: 'language_code_trial_2',
							value: 'en_US',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '32e53c9f-5324-4ff5-87d1-90b4c02e7a77',
							key: 'pvlt_recall_score_trial_1',
							value: '4',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '679c0d9a-fdc5-4320-8e20-f31f76be31bd',
							key: 'pvlt_transcription_confidences_trial_1',
							value: '["0.998","0.997","0.969","0.967","0.966","0.996","0.996","0.997"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '6f7fe683-a075-4d11-9c93-6e3cba6d50ee',
							key: 'pvlt_transcribed_words_trial_1',
							value: '["pineapple","cantaloupe","pliers","uh","stapler","that\'s","about","it"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'bc9b3386-e8a2-4980-b55d-411ab963ca6a',
							key: 'pvlt_speech_active_duration_trial_1',
							value: '12.17',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '30a6d187-2575-453c-b9b1-8e6e82489005',
							key: 'pvlt_speech_start_time_trial_1',
							value: '1',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '9ac5fbc3-8397-4150-b0b9-c82abd5be04c',
							key: 'pvlt_speech_end_time_trial_1',
							value: '13.17',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'da8e263a-deb8-497a-aa30-457e9cf46d24',
							key: 'pvlt_audio_duration_trial_1',
							value: '13.297',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'ffa01632-378c-4ed6-bed8-5de77efe265c',
							key: 'pvlt_expected_words_trial_1',
							value: '["pineapple","drill","stapler","cantaloupe","eraser","pliers"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'ea5ad0ac-7698-451c-99f3-b4662f351404',
							key: 'language_code_trial_1',
							value: 'en_US',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '091b0c1e-b375-4551-beb8-0f2a29f96e41',
							key: 'pvlt_test_type_trial_1',
							value: 'IMMEDIATE_RECALL_6',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '4cc09a4d-a8b1-46fb-8086-138ed474429f',
							key: 'pvlt6_combined_percentile',
							value: '71',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '0a085b70-57c4-4c3b-b887-bf12ce08a100',
							key: 'pvlt6_combined_impairment_cutoff',
							value: '6',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'c9597e33-e1ee-42e7-bd73-a0972d434b25',
							key: 'pvlt_recall_combined_score',
							value: '9',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'f97fa003-3db1-4fec-abd8-8fd14d9d4d6f',
							key: 'pvlt6_trial2_percentile',
							value: '73',
							algorithmVersion: '1.2.6a0',
						},
					],
				},
				{
					id: '10c55924-c021-42e2-b28d-2a297ad322ec',
					segmentId: 'ba3f8b3c-2041-4be9-8ada-45eb42cbde8b',
					rawDataUrl:
						's3://linus-ingest-platform-dev/2101dc0d-3d8e-4387-b814-6f6f64917785/8d11cd6b-86e8-4729-84a0-9c21c8628802/segments/10c55924-c021-42e2-b28d-2a297ad322ec',
					createdAt: '2024-12-17T16:22:37.045Z',
					hasProcessingError: false,
					isExpectingMetrics: true,
					isExpectingResults: true,
					assessmentResultId: '36b85557-9275-4d05-99cf-545c22467e4d',
					hasReceivedResults: false,
					segment: {
						id: 'ba3f8b3c-2041-4be9-8ada-45eb42cbde8b',
						name: 'Semantic Fluency',
						displayKey: 'Semantic Fluency',
						segmentType: 'SEMANTIC_FLUENCY',
						isExpectingResults: true,
						isExpectingMetrics: true,
						metadata: {
							category: SegmentMetadataCategory.Animals,
						},
						audienceType: 'SUBJECT',
					},
					metricItems: [
						{
							id: '0874f81f-1da1-4419-a779-2494b3c8eb7b',
							key: 'recording_duration',
							value: '6.06',
							algorithmVersion: '1.3.2',
						},
						{
							id: '01cdd7c5-b705-4a22-b752-9b108fcae901',
							key: 'semantic_category',
							value: 'animals',
							algorithmVersion: '1.3.2',
						},
						{
							id: 'd83421ce-cb27-4c41-8676-b5de68ed5863',
							key: 'semantic_fluency_impairment_threshold_adjusted_by_education',
							value: 'false',
							algorithmVersion: '1.3.2',
						},
						{
							id: '2c0f1cf5-ca10-4297-82e7-d2535bf3adce',
							key: 'semantic_fluency_impairment_classification',
							value: 'IMPAIRED',
							algorithmVersion: '1.3.2',
						},
						{
							id: '8e1efa8a-ea37-4f97-913f-7d0d36e8cd09',
							key: 'semantic_fluency_impairment_cutoff',
							value: '12',
							algorithmVersion: '1.3.2',
						},
						{
							id: '944c7221-24ac-4152-8e84-dbaa8e019437',
							key: 'semantic_fluency_age_ranges',
							value: '60 - 64',
							algorithmVersion: '1.3.2',
						},
						{
							id: '9e68bdaa-0588-410d-84b2-3c397aa9c96d',
							key: 'semantic_fluency_percentile_float',
							value: '0.0125',
							algorithmVersion: '1.3.2',
						},
						{
							id: '8b5e696b-1450-4e82-bdc1-36ca7051ae5e',
							key: 'semantic_fluency_percentile',
							value: '1',
							algorithmVersion: '1.3.2',
						},
						{
							id: 'f56ace35-0aff-4b92-afb3-b0537590f30a',
							key: 'semantic_fluency_transcribed_words',
							value: '["cat","dog"]',
							algorithmVersion: '1.3.2',
						},
						{
							id: '03beaa3c-df15-4142-95f1-8e018a4275ce',
							key: 'semantic_fluency_repetitions',
							value: '0',
							algorithmVersion: '1.3.2',
						},
						{
							id: 'c39453f7-22d8-40ae-8da1-fe8e2a764af7',
							key: 'semantic_fluency_epoch4_score',
							value: '0',
							algorithmVersion: '1.3.2',
						},
						{
							id: '8ade60da-b3be-4c20-8b06-e0bb9e843690',
							key: 'semantic_fluency_epoch3_score',
							value: '0',
							algorithmVersion: '1.3.2',
						},
						{
							id: '93c542d5-96b2-4a3a-8be4-e68a2a8a85bb',
							key: 'semantic_fluency_epoch2_score',
							value: '0',
							algorithmVersion: '1.3.2',
						},
						{
							id: '33e97a44-fce0-4e91-9eb7-90dcbe900bfc',
							key: 'semantic_fluency_epoch1_score',
							value: '2',
							algorithmVersion: '1.3.2',
						},
						{
							id: '4817331c-3ba7-4b31-9671-6f5fe91c5201',
							key: 'semantic_fluency_total_score',
							value: '2',
							algorithmVersion: '1.3.2',
						},
						{
							id: 'ffe8fc45-9782-4af1-b94a-6c57b080fdd6',
							key: 'language_code',
							value: 'en_US',
							algorithmVersion: '1.3.2',
						},
					],
				},
				{
					id: '8de3438a-20c4-4137-848d-dfc0da7a31af',
					segmentId: '825d55ce-5859-11ee-8c99-0242ac120002',
					rawDataUrl:
						's3://linus-ingest-platform-dev/2101dc0d-3d8e-4387-b814-6f6f64917785/8d11cd6b-86e8-4729-84a0-9c21c8628802/segments/8de3438a-20c4-4137-848d-dfc0da7a31af',
					createdAt: '2024-12-17T16:22:37.045Z',
					hasProcessingError: false,
					isExpectingMetrics: true,
					isExpectingResults: true,
					assessmentResultId: '36b85557-9275-4d05-99cf-545c22467e4d',
					hasReceivedResults: false,
					segment: {
						id: '825d55ce-5859-11ee-8c99-0242ac120002',
						name: 'Backwards Digit Span',
						displayKey: 'Backwards Digit Span',
						segmentType: 'BACKWARDS_DIGIT_SPAN',
						isExpectingResults: true,
						isExpectingMetrics: true,
						metadata: null,
						audienceType: 'SUBJECT',
					},
					metricItems: [
						{
							id: 'bdb9eec3-90d0-445f-8aa4-d3b93a00ac30',
							key: 'backward_digit_span_total_any_order_score_quartile',
							value: '3',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'c25b3e69-320f-4cd5-9882-680596275513',
							key: 'input_uri',
							value: 's3://platform-internal-data-343268922031-use1/b98a0782-9411-4cb7-99b0-2332b9609040/9fa8e8f9-a966-4119-9983-25aa6486064e/backwards_digit_span_engine:1.0/backwards_digit_span_wordset.json',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'de71cf1a-c0c8-40d5-89bf-4ea27331836f',
							key: 'test_language',
							value: 'en_US',
							algorithmVersion: '2.0.8',
						},
						{
							id: '84b741c2-edee-477f-8631-619bf2676fdd',
							key: 'backward_digit_span_trial_1_expected_digits',
							value: '[6,1,3,2,8]',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'ceeb061d-af89-47f8-9f45-7ffbe1302360',
							key: 'backward_digit_span_trial_1_transcribed_digits',
							value: '[6,1,3,2,8]',
							algorithmVersion: '2.0.8',
						},
						{
							id: '850c7b08-6fd9-41d5-a75e-3727db68b8a8',
							key: 'backward_digit_span_trial_1_match_score',
							value: '5',
							algorithmVersion: '2.0.8',
						},
						{
							id: '8fce304d-f200-4a8f-8047-d734808823e0',
							key: 'backward_digit_span_trial_1_order_score',
							value: '5',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'a31b0dbd-9d6d-45ee-898a-fdc17b452d98',
							key: 'backward_digit_span_trial_2_expected_digits',
							value: '[1,9,7,8,4]',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'd548682c-ce2d-42fc-bf3a-93aa326d98d6',
							key: 'backward_digit_span_trial_2_transcribed_digits',
							value: '[1,8,7,8,4]',
							algorithmVersion: '2.0.8',
						},
						{
							id: '619a68c8-2e7b-4f32-ab99-509c92b45959',
							key: 'backward_digit_span_trial_2_match_score',
							value: '4',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'e9aefe7c-329a-427c-b79b-d0db81f9c24f',
							key: 'backward_digit_span_trial_2_order_score',
							value: '4',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'b57e24bd-7052-4de2-b68a-7a3f576f1cca',
							key: 'backward_digit_span_trial_3_expected_digits',
							value: '[9,6,4,5,2]',
							algorithmVersion: '2.0.8',
						},
						{
							id: '8354b3a5-9394-4445-91e8-97df8aa892b7',
							key: 'backward_digit_span_trial_3_transcribed_digits',
							value: '[9,6,4,5,2]',
							algorithmVersion: '2.0.8',
						},
						{
							id: '7884f9c7-4ee0-4e49-9372-0de3178ddbe4',
							key: 'backward_digit_span_trial_3_match_score',
							value: '5',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'c58a4997-c0ac-407f-b30c-36efc488abfe',
							key: 'backward_digit_span_trial_3_order_score',
							value: '5',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'd54de6a3-5200-45df-8dd8-95ba6df6c84f',
							key: 'backward_digit_span_avg_any_order_score',
							value: '4.667',
							algorithmVersion: '2.0.8',
						},
						{
							id: '252b013e-5484-4c6d-a506-fc54d7a4e3da',
							key: 'backward_digit_span_avg_ser_order_score',
							value: '4.667',
							algorithmVersion: '2.0.8',
						},
						{
							id: '96fcecea-f227-4d53-8230-eb38c59be13d',
							key: 'backward_digit_span_total_any_order_score',
							value: '14',
							algorithmVersion: '2.0.8',
						},
						{
							id: '19894b72-ec4b-4743-a18f-df21a98f96b6',
							key: 'backward_digit_span_total_ser_order_score',
							value: '14',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'c76af062-2c78-4f32-bdc1-8e878a436674',
							key: 'backward_digit_span_total_ser_order_score_quartile',
							value: '4',
							algorithmVersion: '2.0.8',
						},
						{
							id: '73d538e4-68a0-4520-b0c2-a1f68ee05cc5',
							key: 'backward_digit_span_age_range',
							value: '60 - 64',
							algorithmVersion: '2.0.8',
						},
						{
							id: '5138f2c3-9d08-4b14-8c01-74c2a3da0754',
							key: 'backward_digit_span_impairment_classification_any_order_score',
							value: 'UNIMPAIRED',
							algorithmVersion: '2.0.8',
						},
						{
							id: '9840f494-4a44-42d5-bea3-2ac1481a9568',
							key: 'backward_digit_span_impairment_classification_ser_order_score',
							value: 'UNIMPAIRED',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'f04afdd0-8c9e-4631-9cc5-934a46f2a93a',
							key: 'backward_digit_span_impairment_cutoff_any_order_score',
							value: '12',
							algorithmVersion: '2.0.8',
						},
						{
							id: '62d8c132-437f-4746-b50d-67f04fe2bc14',
							key: 'backward_digit_span_impairment_cutoff_ser_order_score',
							value: '7',
							algorithmVersion: '2.0.8',
						},
						{
							id: 'ff254cea-b726-4567-98ab-bd2001414fac',
							key: 'backward_digit_span_impairment_threshold_adjusted_by_education_any_order_score',
							value: 'false',
							algorithmVersion: '2.0.8',
						},
						{
							id: '0d4fbc6e-f798-4856-b959-a51970b2f816',
							key: 'backward_digit_span_impairment_threshold_adjusted_by_education_ser_order_score',
							value: 'false',
							algorithmVersion: '2.0.8',
						},
						{
							id: '33a2a585-7a81-4d23-af75-1b5489a3a09d',
							key: 'backward_digit_span_percentile_any_order_score',
							value: '52',
							algorithmVersion: '2.0.8',
						},
						{
							id: '54b28cb7-0673-48bc-9ab8-cc1da3025552',
							key: 'backward_digit_span_percentile_ser_order_score',
							value: '93',
							algorithmVersion: '2.0.8',
						},
					],
				},
				{
					id: '0f10b50a-513b-4b25-b1db-27c0b50439fe',
					segmentId: '5257f696-5c3b-11ee-8c99-0242ac120002',
					rawDataUrl:
						's3://linus-ingest-platform-dev/2101dc0d-3d8e-4387-b814-6f6f64917785/8d11cd6b-86e8-4729-84a0-9c21c8628802/segments/0f10b50a-513b-4b25-b1db-27c0b50439fe',
					createdAt: '2024-12-17T16:22:37.045Z',
					hasProcessingError: false,
					isExpectingMetrics: true,
					isExpectingResults: true,
					assessmentResultId: '36b85557-9275-4d05-99cf-545c22467e4d',
					hasReceivedResults: false,
					segment: {
						id: '5257f696-5c3b-11ee-8c99-0242ac120002',
						name: 'Delayed Recall',
						displayKey: 'Delayed Recall',
						segmentType: 'DELAYED_RECALL_6',
						isExpectingResults: true,
						isExpectingMetrics: true,
						metadata: null,
						audienceType: 'SUBJECT',
					},
					metricItems: [
						{
							id: '25ef5f9b-96f2-4bb9-a25e-a26b5ae765c3',
							key: 'pvlt_recall_score',
							value: '5',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '2c14d3ad-9868-48cc-950f-649d2816eff2',
							key: 'pvlt_transcription_confidences',
							value: '["0.994","0.999","0.996","0.996","0.996","0.998"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'e11ce5c1-c824-44bb-84c3-918eb9154213',
							key: 'pvlt_transcribed_words',
							value: '["uh","pineapple","cantaloupe","drill","eraser","stapler"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'e1287ec6-8896-4508-b249-3647f9afe8f4',
							key: 'pvlt_speech_active_duration',
							value: '6.221',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '29703011-656c-4344-a38c-ec3d6312665f',
							key: 'pvlt_speech_end_time',
							value: '7.23',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'b3e9b33a-c705-4cb5-9c22-50ebfef167f0',
							key: 'pvlt_speech_start_time',
							value: '1.009',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '0d67e247-cfd1-4d57-a6ba-ed7364e527d8',
							key: 'pvlt_audio_duration',
							value: '9.862',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '30516acc-ca37-46de-81a9-14be9fb03d47',
							key: 'pvlt_expected_words',
							value: '["pineapple","drill","stapler","cantaloupe","eraser","pliers"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '4b0ec6db-b681-40f1-9d76-24236045a1b8',
							key: 'pvlt_test_type',
							value: 'DELAYED_RECALL_6',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '9884c878-5f49-4f48-ac4b-eb1513d31516',
							key: 'language_code',
							value: 'en_US',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '194b48e5-3baf-4b6c-bb64-9f89f0d5b677',
							key: 'pvlt6_percentile',
							value: '95',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '4c9dbe99-43e1-4b41-823d-b802d6a166e7',
							key: 'pvlt6_impairment_threshold_adjusted_by_education',
							value: 'false',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'aec5040f-da0a-4f0e-8238-56f8cb558581',
							key: 'pvlt6_impairment_classification',
							value: 'UNIMPAIRED',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'dad68aba-94dc-4a82-b8c5-f4aa0e952f89',
							key: 'pvlt6_impairment_cutoff',
							value: '2',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '9a255386-cc95-45f9-a083-f48f4fa94c15',
							key: 'pvlt6_age_range',
							value: '60 - 64',
							algorithmVersion: '1.2.6a0',
						},
					],
				},
				{
					id: 'ba4ad5a9-fbd7-40e4-a249-22e3a5671172',
					segmentId: '4fa344fa-5c3b-11ee-8c99-0242ac120002',
					rawDataUrl:
						's3://linus-ingest-platform-dev/2101dc0d-3d8e-4387-b814-6f6f64917785/8d11cd6b-86e8-4729-84a0-9c21c8628802/segments/ba4ad5a9-fbd7-40e4-a249-22e3a5671172',
					createdAt: '2024-12-17T16:22:37.045Z',
					hasProcessingError: false,
					isExpectingMetrics: true,
					isExpectingResults: true,
					assessmentResultId: '36b85557-9275-4d05-99cf-545c22467e4d',
					hasReceivedResults: false,
					segment: {
						id: '4fa344fa-5c3b-11ee-8c99-0242ac120002',
						name: 'Delayed Recognition',
						displayKey: 'Delayed Recognition',
						segmentType: 'DELAYED_RECOGNITION',
						isExpectingResults: true,
						isExpectingMetrics: true,
						metadata: null,
						audienceType: 'SUBJECT',
					},
					metricItems: [
						{
							id: '6b6e7a7c-db23-494b-83f8-6bd77c1b1d1c',
							key: 'pvlt_selected_words',
							value: '["pineapple","drill","stapler","cantaloupe","eraser","pliers"]',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'b18cc0ac-8445-4bfd-afde-d3f6361bf382',
							key: 'pvlt_recognition_score',
							value: '6',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'd1be4a4e-945c-402f-a348-ed86d5a4b905',
							key: 'pvlt6_age_range',
							value: '60 - 64',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'fe1d8f01-ca21-4b28-a756-e413cef47437',
							key: 'pvlt6_impairment_cutoff',
							value: '5',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'c752a10e-db8a-412d-9dfd-b3fc9293fc29',
							key: 'pvlt6_impairment_classification',
							value: 'UNIMPAIRED',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '4e3e5272-f5f7-4bc6-9052-d83f8937e8d6',
							key: 'pvlt6_impairment_threshold_adjusted_by_education',
							value: 'false',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'd70a7bd1-87e2-41ed-9be3-f364833b5ae0',
							key: 'pvlt6_percentile',
							value: '100',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: 'c2b4a36d-f388-4a66-adfb-d7e543b66592',
							key: 'pvlt_test_type',
							value: 'DELAYED_RECOGNITION',
							algorithmVersion: '1.2.6a0',
						},
						{
							id: '52f8bf47-8ded-4bb5-973b-6e66c1bf0d6b',
							key: 'pvlt_expected_words',
							value: '["pineapple","drill","stapler","cantaloupe","eraser","pliers"]',
							algorithmVersion: '1.2.6a0',
						},
					],
				},
			],
			assessment: {
				id: '5550e77e-4428-4cce-b590-dc85f61f2312',
				name: 'Linus Health Speech Screener',
				displayKey: 'Linus Health Speech Screener',
				assessmentType: 'DEFAULT',
				isExpectingMetrics: false,
			},
			metricItems: [],
		},
	],
	battery: {
		id: '0664b6b8-b36c-4e3f-913c-3790ddc00966',
		name: 'Digital Assessment of Cognition (DAC)',
		displayKey: 'Digital Assessment of Cognition (DAC)',
		isExpectingMetrics: false,
		batteryType: 'RDAC',
		estimatedDurationMins: 10,
		webEnabled: true,
		mobileEnabled: true,
	},
};

export const DAC_REPORT_DATA: Report = {
	batteryResultById: {
		assessmentResults: DAC_BATTERY_RESULT.assessmentResults,
		// @ts-expect-error
		battery: DAC_BATTERY_RESULT.battery,
		id: DAC_BATTERY_RESULT.id,
		metricItems: DAC_BATTERY_RESULT.metricItems,
	},
};
