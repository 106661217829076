import { styled } from 'styled-components';

export const CardContainer = styled.div`
	background-color: ${({ theme }) => theme.color.white};
	border: 2px solid ${({ theme }) => theme.colors.blue_lighter};
	border-radius: 20px;
	box-shadow: 0px 2px 10px 0px rgba(23, 24, 32, 0.1);
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 24px;
`;

export const ScoresContainer = styled.div`
	display: flex;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.gray_80};
	border-radius: 4px;
	padding: 12px;
	width: 100%;
`;

export const ImpressionLabel = styled.span`
	color: ${({ theme }) => theme.colors.blue};
`;
