import { Typography } from '@lh/eng-web-mosaic-common';
import { Flex, Stack } from '@mantine/core';

import { Icon } from '../../shared/designSystem';

import { BatterySummaryAssessmentResult } from './BatterySummary.types';
import {
	getPillIconByPillStatus,
	getPillStatusByAssessmentStatus,
} from './Pill';
import { getOrdinalSuffix } from './getOrdinalSuffix';

import { Norm, ScorePill, TrialPill, TrialsScorePill } from './ScoreCell.style';

type ScoreCellProps = {
	assessment: BatterySummaryAssessmentResult;
};

export function ScoreCell({ assessment }: Readonly<ScoreCellProps>) {
	const pillStatus = getPillStatusByAssessmentStatus(assessment.status);
	const { background, foreground, icon } =
		getPillIconByPillStatus(pillStatus);

	if (assessment.status === 'RCR') {
		const scoreValue =
			assessment.maxScore !== null ? `--/${assessment.maxScore}` : '--';

		return (
			<Flex align='center' gap={8}>
				<ScorePill $background={background}>
					<Icon
						color={foreground}
						icon={icon}
						iconHeight={16}
						iconWidth={16}
						height={20}
						width={20}
					/>
					<Typography.P2 weight='500'>{scoreValue}</Typography.P2>
				</ScorePill>
				<Typography.P2 weight='500'>Requires review</Typography.P2>
			</Flex>
		);
	}

	const scoreValue =
		assessment.maxScore !== null
			? `${assessment.score}/${assessment.maxScore}`
			: assessment.score;

	if (assessment.resultType === 'TWO_TRIALS') {
		const trial1Score = `${assessment.trial1Score}/${assessment.trial1MaxScore}`;
		const trial2Score = `${assessment.trial2Score}/${assessment.trial2MaxScore}`;

		return (
			<Flex align='center' gap={8}>
				<Stack gap={1}>
					<TrialsScorePill
						$background={background}
						$foreground={foreground}
					>
						<Icon
							color={foreground}
							icon={icon}
							iconHeight={16}
							iconWidth={16}
							height={20}
							width={20}
						/>
						<Typography.P2 weight='500'>{scoreValue}</Typography.P2>
					</TrialsScorePill>
					<TrialPill weight='400'>Trial 1: {trial1Score}</TrialPill>
					<TrialPill weight='400'>Trial 2: {trial2Score}</TrialPill>
				</Stack>
				<Stack gap={0}>
					<Typography.P2>Correct {assessment.unit}</Typography.P2>
					<Norm weight='500'>
						{getOrdinalSuffix(assessment.norm)}{' '}
						{assessment.normType}
					</Norm>
				</Stack>
			</Flex>
		);
	}

	return (
		<Flex gap={8}>
			<ScorePill $background={background}>
				<Icon
					color={foreground}
					icon={icon}
					iconHeight={16}
					iconWidth={16}
					height={20}
					width={20}
				/>
				<Typography.P2 weight='500'>{scoreValue}</Typography.P2>
			</ScorePill>
			<Stack gap={0}>
				<Typography.P2>Correct {assessment.unit}</Typography.P2>
				<Norm weight='500'>
					{getOrdinalSuffix(assessment.norm)} {assessment.normType}
				</Norm>
			</Stack>
		</Flex>
	);
}
