import { theme } from '@lh/eng-web-mosaic-common';

import { styled } from 'styled-components';

import { EnumItem } from '../../../enums/enumeration';
import { icons } from '../../../enums/icons';

import { AssessmentStatus } from './BatterySummary.types';

export type PillStatus = 'SUCCESS' | 'DANGER' | 'NEUTRAL' | 'UNKNOWN';

type PillStatusIcon = {
	background: string;
	foreground: string;
	icon: EnumItem;
};

const PILL_STATUS_ICON: Record<PillStatus, PillStatusIcon> = {
	SUCCESS: {
		background: theme.colors.green_lightest,
		foreground: theme.colors.green,
		icon: icons.CheckmarkSolid,
	},
	DANGER: {
		background: theme.colors.orange_lightest,
		foreground: theme.colors.orange,
		icon: icons.AlertSolid,
	},
	NEUTRAL: {
		background: theme.colors.gray_80,
		foreground: theme.colors.gray_60,
		icon: icons.SubtractSmall,
	},
	UNKNOWN: {
		background: theme.colors.gray_50,
		foreground: theme.colors.gray_10,
		icon: icons.AlertOutline,
	},
};

export function getPillIconByPillStatus(status: PillStatus) {
	return PILL_STATUS_ICON[status];
}

const ASSESSMENT_STATUS_TO_PILL_STATUS: Record<AssessmentStatus, PillStatus> = {
	IMPAIRED: 'DANGER',
	UNIMPAIRED: 'SUCCESS',
	MIXED: 'NEUTRAL',
	RCR: 'UNKNOWN',
};

export function getPillStatusByAssessmentStatus(
	status: AssessmentStatus | 'UNKNOWN'
): PillStatus {
	if (status === 'UNKNOWN') {
		return 'NEUTRAL';
	}

	return ASSESSMENT_STATUS_TO_PILL_STATUS[status];
}

export const Pill = styled.div<{ $background: string }>`
	align-items: center;
	background-color: ${({ $background }) => $background};
	border-radius: 8px;
	display: flex;
	justify-content: center;
`;
