import { SimpleTable } from '@lh/eng-web-mosaic-common';

import { Divider } from '@mantine/core';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styled-components';

import { theme } from 'components/providers/StyleProvider/theme';
import { PHQ_8_MAX_TOTAL_SCORE } from '../../PhqScore';
import i18n from 'i18n';

import {
	ChoicesContainer,
	EndOfQuestionnaire,
	P1Med,
	QuestionTableContainer,
	SelectedChoice,
	SelectedFaded,
	SpanSemi,
	TableContainer,
	TotalLabel,
	TotalScore,
} from './Table.style';
import { Phq8ResultData } from './Table.types';

export type TableProps = {
	resultsTableData: Phq8ResultData[];
};

export function Table({ resultsTableData }: Readonly<TableProps>) {
	const { t } = useTranslation();
	return (
		<TableContainer>
			<StyledTable
				borderColor={theme.colors.gray_60}
				columns={PHQ_TABLE_COLUMNS}
				data={resultsTableData}
				striped='odd'
				horizontalSpacing={theme.spacing.sm}
				verticalSpacing={theme.spacing.sm}
			/>
			<Divider color={theme.colors.gray_60} />
			<EndOfQuestionnaire>
				{t('web.report.gad7.answersCard.end')}
			</EndOfQuestionnaire>
		</TableContainer>
	);
}

const PHQ_TABLE_COLUMNS: ColumnDef<Phq8ResultData>[] = [
	{
		accessorKey: 'question',
		header: () => (
			<P1Med>{i18n.t('web.report.gad7.answersCard.question')}</P1Med>
		),
		cell: (props: CellContext<Phq8ResultData, unknown>) => {
			const value = props.getValue<Phq8ResultData['question']>();
			const rows = props.table.getRowModel().rows;
			if (props.row.index === rows.length - 1) {
				return <TotalLabel>{value}</TotalLabel>;
			}

			return (
				<QuestionTableContainer>
					<SpanSemi>Q{props.row.index + 1}: </SpanSemi>
					{value.replace(`${props.row.index + 1}. `, '')}
				</QuestionTableContainer>
			);
		},
	},
	{
		accessorKey: 'answers',
		header: () => (
			<P1Med>{i18n.t('web.report.gad7.answersCard.selections')}</P1Med>
		),
		cell: (props: CellContext<Phq8ResultData, unknown>) => {
			const answers = props.getValue<Phq8ResultData['answers']>();
			if ('totalScore' in answers) {
				return (
					<TotalScore>{`${answers.totalScore}/${PHQ_8_MAX_TOTAL_SCORE}`}</TotalScore>
				);
			}

			return (
				<ChoicesContainer>
					{answers.choices?.map((choice) => {
						return choice.answer ? (
							<SelectedChoice key={choice.text}>
								{choice.text}
							</SelectedChoice>
						) : (
							<SelectedFaded key={choice.text}>
								{choice.text}
							</SelectedFaded>
						);
					})}
				</ChoicesContainer>
			);
		},
	},
];

const StyledTable = styled(SimpleTable<Phq8ResultData>)(
	({ theme }) => css`
		tbody > tr:last-child {
			background-color: white;
			border-top: 1px solid ${theme.colors.gray_60};
		}

		td {
			width: 50%;
			vertical-align: top;
		}
	`
);
