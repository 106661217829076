import styled, { css } from 'styled-components';

import { H2 } from 'components/shared/designSystem';

export const Phq8LayoutContainer = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${spacing.lg};
	`
);

export const H2Med = styled(H2)(
	({ theme: { weight } }) => css`
		font-weight: ${weight.medium};
		line-height: 44px;
	`
);
