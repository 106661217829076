import { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { SVGProps } from '../../../types';
import * as Icons from './Icons/svgs/Svgs';

export const Icon = ({
	icon,
	width,
	height,
	iconWidth,
	iconHeight,
	color = '#676671',
	strokeColor,
	title,
	onClick,
	onMouseEnter,
	onMouseLeave,
	tabIndex = 0,
	dataId,
	disabled,
	visible = true,
	viewBox,
}: SVGProps): JSX.Element => {
	if (!icon) {
		throw new Error(
			'You must provide a valid icon from Icons enum. Check that you have spelled the icon name correctly'
		);
	}
	const size =
		iconWidth && iconHeight
			? { width: iconWidth, height: iconHeight }
			: { width: icon.width, height: icon.height };
	const optionalViewBox = viewBox != null ? { viewBox } : {};
	const I = (
		Icons as Record<
			string,
			React.FunctionComponent<
				React.SVGProps<SVGSVGElement> & {
					title?: string | undefined;
				}
			>
		>
	)[icon.key];
	return (
		<StyledIcon
			data-testid={dataId}
			tabIndex={tabIndex}
			role='button'
			onClick={(e: SyntheticEvent) =>
				onClick && !disabled ? onClick(e) : undefined
			}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			width={width}
			height={height}
			$visible={visible}
		>
			<I
				{...size}
				stroke={strokeColor}
				fill={color}
				title={title}
				{...optionalViewBox}
			/>
		</StyledIcon>
	);
};

type StyledIconProps = {
	width?: string | number;
	height?: string | number;
	$visible?: boolean;
	onClick?: (e: SyntheticEvent) => void;
};
const StyledIcon = styled.div<StyledIconProps>(
	({ width, height, onClick, $visible }) => `
	display: flex;
	${typeof width === 'number' ? `width:${width}px;` : ''};
	${typeof width === 'string' ? `width:${width};` : ''};
	${typeof height === 'number' ? `height:${height}px;` : ''};
	${typeof height === 'string' ? `height:${height};` : ''};
	${onClick ? 'cursor: pointer;' : ''}
	align-items: center;
	justify-content: center;
	${$visible ? '' : 'visibility: hidden;'};
`
);
