type AssessmentSummary = {
	assessment_status: 'IMPAIRED' | 'UNIMPAIRED' | 'MIXED' | 'RCR';
	key_results: {
		max_score: number | null;
		norm: number;
		norm_type: 'Percentile' | 'Quartile';
		result_type: 'DEFAULT' | 'TWO_TRIALS';
		score: number;
		trial1_max_score?: number;
		trial1_score?: number;
		trial2_max_score?: number;
		trial2_score?: number;
		unit: string;
	};
	name: string;
	relevant_domains: string[];
	requires_clinician_review: boolean;
};
type AssessmentSummaries = AssessmentSummary[];

type DomainResult = {
	domain_name: string;
	domain_status: 'GREEN' | 'RED' | 'YELLOW';
	domain_status_detail: string;
};
type DomainResults = DomainResult[];

type SummaryResult = {
	impaired_domains: string[];
	impression: 'UNIMPAIRED' | 'IMPAIRED' | 'MIXED';
	mixed_domains: string[];
	unimpaired_domains: string[];
};

export enum DACMetricKey {
	AssessmentSummaries = 'rdac_assessment_summaries',
	DomainResults = 'rdac_domain_results',
	SummaryResult = 'rdac_summary_result',
}

export type DACMetrics = {
	[DACMetricKey.AssessmentSummaries]: AssessmentSummaries;
	[DACMetricKey.DomainResults]: DomainResults;
	[DACMetricKey.SummaryResult]: SummaryResult;
};
