import {
	AnswerTypes,
	GradeMarks,
	QuestionTypes_instruction,
	QuestionTypes_singleSelect,
	QuestionnaireScoringResultIds,
	QuestionnaireSpecWithScoringResult,
} from '@lh/qnr-svc-rest-client';

import { getInfoFromType } from '../Phq8Report.utils';

import { Phq8ResultData } from './IndividualAnswers/Table';

export const PHQ8_TABLE_DATA: Phq8ResultData[] = [
	{
		question: '1. Little interest or pleasure in doing things',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '2. Feeling down, depressed, or hopeless',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '3. Trouble falling or staying asleep, or sleeping too much',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '1',
					},
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '4. Feeling tired or having little energy',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: {
						value: '3',
						grade: GradeMarks.NOT_GRADED,
						score: '3',
					},
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question: '5. Poor appetite or overeating',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question:
			'6. Feeling bad about yourself or that you are a failure or have let yourself or your family down',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: null,
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: {
						value: '3',
						grade: GradeMarks.NOT_GRADED,
						score: '3',
					},
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question:
			'7. Trouble concentrating on things, such as reading the newspaper or watching television',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question:
			'8. Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
					text: 'Not at all',
				},
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: null,
					text: 'Several days',
				},
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: null,
					text: 'More than half the days',
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
					text: 'Nearly every day',
				},
			],
		},
	},
	{
		question:
			'9. If you checked any problems, how difficult have they made it for you to do your work, take care of things at home, or get along with other people?',
		answers: {
			choices: [
				{
					choiceI18nKey: 'not-difficult',
					value: 0,
					answer: null,
					text: 'Not difficult at all',
				},
				{
					choiceI18nKey: 'somewhat-difficult',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
					text: 'Somewhat difficult',
				},
				{
					choiceI18nKey: 'very-difficult',
					value: 2,
					answer: null,
					text: 'Very difficult',
				},
				{
					choiceI18nKey: 'extremely-difficult',
					value: 3,
					answer: null,
					text: 'Extremely difficult',
				},
			],
		},
	},
	{
		question: 'TOTAL SCORE',
		answers: {
			totalScore: 11,
		},
	},
];

export const Phq8DataResult: QuestionnaireSpecWithScoringResult = {
	id: 'phq-8',
	version: '1.0.0',
	name: 'Patient Health Questionnaire (PHQ-8)',
	description:
		'This activity will ask you about your feelings and experiences over the past 2 weeks.',
	localizations: [
		{
			locale: 'en_US',
			controlTranslations: {
				progress: 'Question {{count}} of {{length}}',
				start: 'Start',
				next: 'Next',
				back: 'Back',
				done: 'Done',
			},
			translations: [
				{
					key: 'phq-instructions-1',
					translation: 'In this task, you will see 8 questions.',
				},
				{
					key: 'phq-instructions-2',
					translation:
						'For each question, tap one answer and then press the next button.  You can go back and change your answer at any time in the task.',
				},
				{
					key: 'phq-instructions-3',
					translation: 'Press start when you are ready to begin.',
				},
				{
					key: 'over-the-last-two-weeks',
					translation:
						'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
				},
				{
					key: 'little-interest-or-pleasure',
					translation:
						'1. Little interest or pleasure in doing things',
				},
				{
					key: 'feeling-down',
					translation: '2. Feeling down, depressed, or hopeless',
				},
				{
					key: 'trouble-falling-asleep',
					translation:
						'3. Trouble falling or staying asleep, or sleeping too much',
				},
				{
					key: 'feeling-tired',
					translation: '4. Feeling tired or having little energy',
				},
				{
					key: 'poor-appetite',
					translation: '5. Poor appetite or overeating',
				},
				{
					key: 'feeling-bad',
					translation:
						'6. Feeling bad about yourself or that you are a failure or have let yourself or your family down',
				},
				{
					key: 'trouble-concentrating',
					translation:
						'7. Trouble concentrating on things, such as reading the newspaper or watching television',
				},
				{
					key: 'moving-slowly',
					translation:
						'8. Moving or speaking so slowly that other people could have noticed. Or the opposite being so fidgety or restless that you have been moving around a lot more than usual',
				},
				{
					key: 'how-difficult',
					translation:
						'9. If you checked any problems, how difficult have they made it for you to do your work, take care of things at home, or get along with other people?',
				},
				{ key: 'not-at-all', translation: 'Not at all' },
				{ key: 'several-days', translation: 'Several days' },
				{
					key: 'more-than-half',
					translation: 'More than half the days',
				},
				{
					key: 'nearly-every-day',
					translation: 'Nearly every day',
				},
				{
					key: 'not-difficult',
					translation: 'Not difficult at all',
				},
				{
					key: 'somewhat-difficult',
					translation: 'Somewhat difficult',
				},
				{ key: 'very-difficult', translation: 'Very difficult' },
				{
					key: 'extremely-difficult',
					translation: 'Extremely difficult',
				},
				{ key: 'not-applicable', translation: 'Not applicable' },
			],
		},
		{
			locale: 'es_ES',
			controlTranslations: {
				progress: 'Question {{count}} of {{length}}',
				start: 'Start',
				next: 'Next',
				back: 'Back',
				done: 'Done',
			},
			translations: [
				{
					key: 'phq-instructions-1',
					translation: 'Spanish translation',
				},
				{
					key: 'phq-instructions-2',
					translation: 'Spanish translation',
				},
				{
					key: 'phq-instructions-3',
					translation: 'Spanish translation',
				},
				{
					key: 'over-the-last-two-weeks',
					translation: 'Spanish translation',
				},
				{
					key: 'little-interest-or-pleasure',
					translation: 'Spanish translation',
				},
				{ key: 'feeling-down', translation: 'Spanish translation' },
				{
					key: 'trouble-falling-asleep',
					translation: 'Spanish translation',
				},
				{
					key: 'feeling-tired',
					translation: 'Spanish translation',
				},
				{
					key: 'poor-appetite',
					translation: 'Spanish translation',
				},
				{ key: 'feeling-bad', translation: 'Spanish translation' },
				{
					key: 'trouble-concentrating',
					translation: 'Spanish translation',
				},
				{
					key: 'moving-slowly',
					translation: 'Spanish translation',
				},
				{
					key: 'how-difficult',
					translation: 'Spanish translation',
				},
				{ key: 'not-at-all', translation: 'Spanish translation' },
				{ key: 'several-days', translation: 'Spanish translation' },
				{
					key: 'more-than-half',
					translation: 'Spanish translation',
				},
				{
					key: 'nearly-every-day',
					translation: 'Spanish translation',
				},
				{
					key: 'not-difficult',
					translation: 'Spanish translation',
				},
				{
					key: 'somewhat-difficult',
					translation: 'Spanish translation',
				},
				{
					key: 'very-difficult',
					translation: 'Spanish translation',
				},
				{
					key: 'extremely-difficult',
					translation: 'Spanish translation',
				},
				{
					key: 'not-applicable',
					translation: 'Spanish translation',
				},
			],
		},
	],
	components: [
		{
			id: 'instructions',
			type: QuestionTypes_instruction.INSTRUCTION,
			instructionsI18nKeys: [
				'phq-instructions-1',
				'phq-instructions-2',
				'phq-instructions-3',
			],
		},
		{
			id: 'phq8_q1',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'little-interest-or-pleasure',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
				},
				{ choiceI18nKey: 'several-days', value: 1, answer: null },
				{ choiceI18nKey: 'more-than-half', value: 2, answer: null },
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '0',
		},
		{
			id: 'phq8_q2',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'feeling-down',
			choices: [
				{ choiceI18nKey: 'not-at-all', value: 0, answer: null },
				{ choiceI18nKey: 'several-days', value: 1, answer: null },
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '2',
		},
		{
			id: 'phq8_q3',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'trouble-falling-asleep',
			choices: [
				{ choiceI18nKey: 'not-at-all', value: 0, answer: null },
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '1',
					},
				},
				{ choiceI18nKey: 'more-than-half', value: 2, answer: null },
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '1',
		},
		{
			id: 'phq8_q4',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'feeling-tired',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
				},
				{ choiceI18nKey: 'several-days', value: 1, answer: null },
				{ choiceI18nKey: 'more-than-half', value: 2, answer: null },
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '0',
		},
		{
			id: 'phq8_q5',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'poor-appetite',
			choices: [
				{
					choiceI18nKey: 'not-at-all',
					value: 0,
					answer: {
						value: '0',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
				},
				{ choiceI18nKey: 'several-days', value: 1, answer: null },
				{ choiceI18nKey: 'more-than-half', value: 2, answer: null },
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '0',
		},
		{
			id: 'phq8_q6',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'feeling-bad',
			choices: [
				{ choiceI18nKey: 'not-at-all', value: 0, answer: null },
				{ choiceI18nKey: 'several-days', value: 1, answer: null },
				{ choiceI18nKey: 'more-than-half', value: 2, answer: null },
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: {
						value: '3',
						grade: GradeMarks.NOT_GRADED,
						score: '3',
					},
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '3',
		},
		{
			id: 'phq8_q7',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'trouble-concentrating',
			choices: [
				{ choiceI18nKey: 'not-at-all', value: 0, answer: null },
				{ choiceI18nKey: 'several-days', value: 1, answer: null },
				{
					choiceI18nKey: 'more-than-half',
					value: 2,
					answer: {
						value: '2',
						grade: GradeMarks.NOT_GRADED,
						score: '2',
					},
				},
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '2',
		},
		{
			id: 'phq8_q8',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: true,
			headerI18nKey: 'over-the-last-two-weeks',
			promptI18nKey: 'moving-slowly',
			choices: [
				{ choiceI18nKey: 'not-at-all', value: 0, answer: null },
				{
					choiceI18nKey: 'several-days',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '1',
					},
				},
				{ choiceI18nKey: 'more-than-half', value: 2, answer: null },
				{
					choiceI18nKey: 'nearly-every-day',
					value: 3,
					answer: null,
				},
			],
			answerType: AnswerTypes.ANSWERED,
			score: '1',
		},
		{
			id: 'phq8_q9',
			type: QuestionTypes_singleSelect.SINGLE_SELECT,
			required: false,
			promptI18nKey: 'how-difficult',
			choices: [
				{ choiceI18nKey: 'not-difficult', value: 0, answer: null },
				{
					choiceI18nKey: 'somewhat-difficult',
					value: 1,
					answer: {
						value: '1',
						grade: GradeMarks.NOT_GRADED,
						score: '0',
					},
				},
				{ choiceI18nKey: 'very-difficult', value: 2, answer: null },
				{
					choiceI18nKey: 'extremely-difficult',
					value: 3,
					answer: null,
				},
				{ choiceI18nKey: 'not-applicable', value: 4, answer: null },
			],
			answerType: AnswerTypes.ANSWERED,
			score: '0',
		},
	],
	scores: {
		total: { id: QuestionnaireScoringResultIds.TOTAL, score: '9' },
	},
};
//Not currently useful, but I hear this is something that will come eventually so leaving it
export const PHQ_INFO_EXAMPLE = {
	default: {
		score: '0',
		tableData: PHQ8_TABLE_DATA,
		...getInfoFromType(),
	},
};
