import { theme, Typography } from '@lh/eng-web-mosaic-common';
import { Stack } from '@mantine/core';

import { useTranslation } from 'react-i18next';

export function SummaryDisclaimer() {
	const { t } = useTranslation();

	const criteria: string[] = t('web.report.summary.footer.criteria', {
		returnObjects: true,
	});

	return (
		<Stack gap={8}>
			<Stack gap={0}>
				{criteria.map((criterion) => (
					<Typography.C
						color={theme.color.bodyTextSecondary}
						key={criterion}
						weight='400'
					>
						{criterion}
					</Typography.C>
				))}
			</Stack>
			<Typography.C color={theme.color.bodyTextSecondary} weight='400'>
				{t('web.report.summary.footer.disclaimer')}
			</Typography.C>
		</Stack>
	);
}
