import { theme } from '@lh/eng-web-mosaic-common';

import styled, { css } from 'styled-components';

import { DomainStatus } from './DomainScore.types';

const DOMAIN_STATUS_TO_COLOR: Record<
	DomainStatus,
	{ background: string; border: string }
> = {
	GREEN: {
		background: theme.colors.green_lightest,
		border: theme.colors.green_light,
	},
	RED: {
		background: theme.colors.orange_lightest,
		border: theme.colors.orange_light,
	},
	YELLOW: {
		background: theme.colors.yellow_lightest,
		border: theme.colors.yellow_light,
	},
};

export const ScoreCell = styled.div<{
	$status: DomainStatus;
}>(
	({ $status }) => css`
		display: flex;
		justify-content: center;
		align-items: center;
		background: ${DOMAIN_STATUS_TO_COLOR[$status].background};
		border: 1px solid ${DOMAIN_STATUS_TO_COLOR[$status].border};
		border-radius: 4px;
		gap: 4px;
		min-height: 40px;
		height: 100%;
		width: 60px;
	`
);
