import { useTranslation } from 'react-i18next';

import { ReportInfoPanel } from 'components/mosaic';

import { Phq8LayoutContainer } from './Phq8Layout.style';
import { PhqScore } from './PhqScore';
import { IndividualAnswers } from './IndividualAnswers';
import { Phq8ResultData } from './IndividualAnswers/Table';

export type Phq8LayoutProps = {
	score: string;
	color: string;
	text: string;
	tableData: Phq8ResultData[];
};

export function Phq8Layout({
	color,
	tableData,
	score,
	text,
}: Readonly<Phq8LayoutProps>) {
	const { t } = useTranslation();

	return (
		<Phq8LayoutContainer>
			<ReportInfoPanel title={t('web.report.phq8.titleReport')}>
				<PhqScore color={color} score={score} text={text} />
			</ReportInfoPanel>
			<IndividualAnswers resultsTableData={tableData} />
		</Phq8LayoutContainer>
	);
}
