import { theme, Typography } from '@lh/eng-web-mosaic-common';
import { Flex, Stack } from '@mantine/core';

import React from 'react';

import { icons } from '../../../enums/icons';
import { Icon } from '../../shared/designSystem';

import {
	BatterySummaryAssessmentResult,
	BatterySummaryDomainResult,
} from './BatterySummary.types';
import { IconPill } from './IconPill';
import { ScoreCell } from './ScoreCell';

import {
	EmptyTData,
	EmptyTHeader,
	FloatingHeader,
	Table,
	TData,
	THeader,
} from './ResultsByDomainTable.style';

type ResultsByDomainTableProps = {
	assessmentResults: BatterySummaryAssessmentResult[];
	domainResults: BatterySummaryDomainResult[];
	floatingLabel: string;
	scoreColumnName: string;
};

export function ResultsByDomainTable({
	assessmentResults,
	domainResults,
	floatingLabel,
	scoreColumnName,
}: Readonly<ResultsByDomainTableProps>) {
	const size = domainResults.length > 4 ? 'small' : 'large';

	return (
		<Table size={size}>
			<FloatingHeader size={size}>
				<tr>
					<td>
						<Typography.P2 weight='500'>
							{floatingLabel}
						</Typography.P2>
					</td>
				</tr>
			</FloatingHeader>
			<thead>
				<tr>
					<THeader />
					<THeader>
						<Typography.P1 weight='500'>
							{scoreColumnName}
						</Typography.P1>
					</THeader>
					{domainResults.map((domain) => (
						<React.Fragment key={domain.name}>
							<EmptyTHeader />
							<THeader status={domain.status}>
								<Flex gap={2}>
									{domain.status === 'RED' && (
										<Icon
											color={theme.color.alertError}
											icon={icons.ArrowFlag}
											height={20}
											width={20}
										/>
									)}
									<Stack gap={2}>
										<Typography.P1 weight='500'>
											{domain.name}
										</Typography.P1>
										<Typography.P2 color=''>
											{domain.details}
										</Typography.P2>
									</Stack>
								</Flex>
							</THeader>
						</React.Fragment>
					))}
				</tr>
			</thead>
			<tbody>
				{assessmentResults.map((assessment) => {
					return (
						<tr key={assessment.name}>
							<TData>
								<Typography.P2 weight='600'>
									{assessment.name}
								</Typography.P2>
							</TData>
							<TData>
								<ScoreCell assessment={assessment} />
							</TData>
							{domainResults.map((domain) => {
								const isDomainAffected =
									assessment.domains.some(
										(rd) => rd === domain.name
									);
								if (!isDomainAffected) {
									return (
										<React.Fragment
											key={`${assessment.name}-${domain.name}`}
										>
											<EmptyTData />
											<TData status={domain.status}>
												<IconPill />
											</TData>
										</React.Fragment>
									);
								}

								return (
									<React.Fragment
										key={`${assessment.name}-${domain.name}`}
									>
										<EmptyTData />
										<TData status={domain.status}>
											<IconPill
												status={assessment.status}
											/>
										</TData>
									</React.Fragment>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}
