import { styled } from 'styled-components';

import { Icon } from '../../shared/designSystem';
import { AssessmentStatus } from './BatterySummary.types';

import {
	getPillIconByPillStatus,
	getPillStatusByAssessmentStatus,
	Pill,
} from './Pill';

type IconPillProps = {
	status?: AssessmentStatus;
};

export function IconPill({ status }: Readonly<IconPillProps>) {
	const pillStatus = getPillStatusByAssessmentStatus(status ?? 'UNKNOWN');
	const { background, foreground, icon } =
		getPillIconByPillStatus(pillStatus);

	return (
		<CustomPill $background={background}>
			<Icon
				color={foreground}
				dataId='pill-icon'
				icon={icon}
				iconHeight={16}
				iconWidth={16}
				height={20}
				width={20}
			/>
		</CustomPill>
	);
}

const CustomPill = styled(Pill)`
	height: 24px;
`;
